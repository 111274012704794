import { createRef, useState } from 'react';
import Button from '../Button/Button';
import { useAppSelector } from '../../../store/hooks';
import { useTranslations } from '../../../hooks/translations/useTranslations';

import './imageUploader.css';

interface ImageUploaderProps {
    onImageSelection: (selectedImage: string) => void;
    onUploadImage: (formData: FormData) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
    onImageSelection,
    onUploadImage,
}) => {
    const auth = useAppSelector(state => state.auth);
    const t = useTranslations();

    const [image, setImage] = useState<File>();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const inputRef = createRef<HTMLInputElement>();

    const handleUploadClick = () => {
        inputRef.current?.click();
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newImage = event.target.files[0];
            if (newImage) {
                const url = URL.createObjectURL(newImage);
                setSelectedImage(url);
                setImage(newImage);
                onImageSelection(url);
            }
        }
    };

    const handleSaveImage = async () => {
        if (image) {
            const formData = new FormData();
            formData.append('file', image);

            onUploadImage(formData);
        }
    };

    return (
        <div>
            <input
                ref={inputRef}
                accept="image/*"
                hidden
                id="avatar-image-upload"
                type="file"
                onChange={handleOnChange}
            />
            <label htmlFor="avatar-image-upload">
                {selectedImage ? (
                    <Button
                        onClick={handleSaveImage}
                        text={t('userSettings.saveImage')}
                        size="sm"
                        variant="retro"
                        disabled={auth.isLoading}
                    />
                ) : (
                    <Button
                        onClick={handleUploadClick}
                        text={t('userSettings.changeImage')}
                        size="sm"
                        variant="retro"
                    />
                )}
            </label>
        </div>
    );
};

export default ImageUploader;
