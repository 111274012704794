import { useState } from 'react';
import {
    fetchCountries,
    fetchCities,
    fetchCountry,
    fetchAllCountries,
    fetchAllCountryCities,
} from '../../api/geoData';
import { CitiesResponse, CountriesResponse } from '../../utils/types';

export const useGeoData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [countriesData, setCountriesData] = useState<
        CountriesResponse[] | null
    >(null);
    const [countryData, setCountryData] = useState<CountriesResponse | null>(
        null
    );
    const [citiesData, setCitiesData] = useState<CitiesResponse[] | null>(null);
    const [error, setError] = useState(null);

    const getCountries = async (initials: string) => {
        try {
            setIsLoading(true);
            const data = await fetchCountries(initials);
            setCountriesData(data);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const getAllCountries = async () => {
        try {
            setIsLoading(true);
            const data = await fetchAllCountries();
            setCountriesData(data);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const getCities = async (countryCode: string, initials: string) => {
        try {
            setIsLoading(true);
            if (countryCode) {
                const data = await fetchCities(countryCode, initials);
                setCitiesData(data);
            } else {
                throw new Error('Invalid country code');
            }
        } catch (error: any) {
            console.warn(error);
            setError(error.message);
        }
        setIsLoading(false);
    };

    const getAllCountryCities = async (countryCode: string) => {
        try {
            setIsLoading(true);
            if (countryCode) {
                const data = await fetchAllCountryCities(countryCode);
                setCitiesData(data);
            } else {
                throw new Error('Invalid country code');
            }
        } catch (error: any) {
            console.warn(error);
            setError(error.message);
        }
        setIsLoading(false);
    };

    const getCountry = async (countryCode: string) => {
        try {
            setIsLoading(true);
            if (countryCode) {
                const data = await fetchCountry(countryCode);
                setCountryData(data);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    return {
        isLoading,
        countriesData,
        countryData,
        citiesData,
        error,
        getCountries,
        getCities,
        getCountry,
        getAllCountries,
        getAllCountryCities,
    };
};
