import axios from 'axios';
import globalRouter from './globalRouter';

const mockRequest = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

// mockRequest.interceptors.request.use(config => {
//     const token = localStorage.getItem('jwt');

//     const newConfig = config;

//     if (newConfig.headers) {
//         newConfig.headers.Authorization = `Bearer ${token}`;
//     }

//     return newConfig;
// });

mockRequest.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 404 && globalRouter.navigate) {
            globalRouter.navigate('/not-found');
        }
        if (error.response.status === 401 && globalRouter.navigate) {
            globalRouter.navigate('/login');
        }
        return Promise.reject(error);
    }
);

export default mockRequest;
