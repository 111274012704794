import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGeoData } from '../../hooks/geoData/useGeoData';
import { isValidEmail, isValidPassword } from '../../utils/helpers';
import { OptionType, RegisterPayload } from '../../utils/types';
import {
    Select,
    Title,
    TextField,
    TextInput,
    Button,
    GenericError,
} from '../common';
import { LANGUAGES, languageOptions } from '../../utils/consts';
import { register } from '../../api/auth';
import { getCurrentUser } from '../../store/auth/authSlice';
import { useTranslations } from '../../hooks/translations/useTranslations';

import './register.css';

const initialFormValues = {
    name: null,
    surname: null,
    email: null,
    password: null,
    country: null,
    city: null,
    language: LANGUAGES.EN,
};

interface FormValues {
    name: string | null;
    surname: string | null;
    email: string | null;
    password: string | null;
    country: string | null;
    city: string | null;
    language: LANGUAGES;
}

interface Errors {
    name: string | null;
    surname: string | null;
    email: string | null;
    password: string | null;
    country: string | null;
    city: string | null;
    language: string | null;
}

const Register = () => {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const t = useTranslations();

    const {
        isLoading: isGeoDataLoading,
        error: geoDataError,
        getAllCountryCities,
        countriesData,
        citiesData,
        getAllCountries,
    } = useGeoData();

    const [formValues, setFormValues] = useState<FormValues>(initialFormValues);
    const [errors, setErrors] = useState<Errors>({
        ...initialFormValues,
        language: null,
    });
    const [countryCode, setCountryCode] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getAllCountries();
        }
    }, [getAllCountries]);

    useEffect(() => {
        if (auth.authData) {
            navigate('/dashboard', { replace: true });
        }
    }, [auth, navigate]);

    const inputChangeHandler = (name: string, value: string) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({
            ...initialFormValues,
            language: null,
        });
    };

    const onSubmitHandler = async (event: React.FormEvent) => {
        event.preventDefault();

        let hasErrors = false;
        const formErrors = { ...errors };
        let key: keyof FormValues;
        for (key in formValues) {
            if (formValues[key] === null) {
                hasErrors = true;
                formErrors[key] = 'This field is required';
            }
        }

        if (hasErrors) {
            setErrors({
                ...formErrors,
            });
            return;
        }

        if (formValues.email && !isValidEmail(formValues.email)) {
            setErrors({ ...errors, email: 'Invalid email address' });
            return;
        }

        if (
            !formValues.password ||
            (formValues.password && !isValidPassword(formValues.password))
        ) {
            setErrors({
                ...errors,
                password:
                    'Password must have at least one uppercase letter, one lowercase, one number and one special character',
            });
            return;
        }

        const payload: RegisterPayload = {
            ...formValues,
        };
        try {
            setIsLoading(true);
            await register(payload);

            dispatch(getCurrentUser());
            setIsLoading(false);
        } catch (e: any) {
            if (e.response.status === 409) {
                setErrors({
                    ...formErrors,
                    ...e.response.data,
                });
                setIsLoading(false);
            }
            if (e.response.status === 400) {
                console.log(e.response);

                setErrors({
                    ...formErrors,
                    [e.response.data.errors[0].path]:
                        e.response.data.errors[0].msg,
                });
            }
            if (e.response.status === 500) {
                setError(true);
            }
            setIsLoading(false);
        }
    };

    const generateOptions = useMemo(() => {
        const countryOptions: OptionType[] = [];
        const citiesOptions: OptionType[] = [];
        countriesData?.forEach(country => {
            countryOptions.push({
                id: country.code,
                label: country.name,
            });
        });
        citiesData?.forEach(city => {
            citiesOptions.push({
                id: city.name,
                label: city.name,
            });
        });
        return { countryOptions, citiesOptions };
    }, [countriesData, citiesData]);

    const onCountryChange = (fieldName: string, result: OptionType) => {
        getAllCountryCities(result.id as string);
        setCountryCode(result.id as string);
        getAllCountryCities(result.id as string);

        setFormValues({
            ...formValues,
            city: null,
            [fieldName]: result.label,
        });

        setErrors({
            ...errors,
            country: null,
            city: null,
        });
    };

    const onCityChange = (fieldName: string, result: OptionType) => {
        setFormValues({
            ...formValues,
            [fieldName]: result.label,
        });

        setErrors({
            ...errors,
            country: null,
            city: null,
        });
    };

    const onLanguageChange = (fieldName: string, result: OptionType) => {
        setFormValues({
            ...formValues,
            [fieldName]: result.id,
        });
    };

    if (geoDataError || error) {
        return <GenericError />;
    }

    return (
        <main className="register-main">
            <div className="register-title">
                <Title text={t('register.title')} size="xxl" />
            </div>
            <form onSubmit={onSubmitHandler} className="register-form">
                <TextInput
                    label={t('register.name')}
                    name="name"
                    placeholder={t('register.name')}
                    onChange={inputChangeHandler}
                    value={formValues.name}
                    error={errors.name}
                />
                <TextInput
                    label={t('register.surname')}
                    name="surname"
                    placeholder={t('register.surname')}
                    onChange={inputChangeHandler}
                    value={formValues.surname}
                    error={errors.surname}
                />
                <TextInput
                    label="Email"
                    name="email"
                    placeholder="Email"
                    onChange={inputChangeHandler}
                    value={formValues.email}
                    error={errors.email}
                />
                <TextInput
                    label="Password"
                    name="password"
                    placeholder="Password"
                    onChange={inputChangeHandler}
                    value={formValues.password}
                    error={errors.password}
                    type="password"
                />
                <Select
                    name="language"
                    options={languageOptions}
                    onSelect={onLanguageChange}
                    value={formValues.language || ''}
                    label={t('register.language')}
                    className="full-width"
                    error={errors.language}
                    isDisabled={auth.isLoading || isGeoDataLoading}
                />
                <Select
                    name="country"
                    options={generateOptions.countryOptions}
                    onSelect={onCountryChange}
                    value={countryCode as string}
                    label={t('register.country')}
                    className="full-width"
                    error={errors.country}
                    isDisabled={auth.isLoading || isGeoDataLoading}
                />
                {formValues.country && (
                    <Select
                        name="city"
                        options={generateOptions.citiesOptions}
                        onSelect={onCityChange}
                        value={formValues.city || ''}
                        label={t('register.city')}
                        className="full-width"
                        isDisabled={auth.isLoading}
                    />
                )}
                <Button
                    type="submit"
                    text={t('register.signUp')}
                    variant="green"
                    disabled={isLoading}
                />
            </form>
            <div className="signin-link">
                <TextField>{t('register.bottomText')}</TextField>
                <Link to="/login">
                    <TextField color="green">{t('register.signIn')}</TextField>
                </Link>
            </div>
        </main>
    );
};

export default Register;
