import { useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import homepageImage2 from '../../images/homepage-image-2.jpg';
import homepageImage5 from '../../images/homepage-image-5.jpg';
import { Button, TextField, Title } from '../common';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { LANGUAGES } from '../../utils/consts';

import './homepage.css';

const Homepage = () => {
    const language = navigator.language
        .split('-')[0]
        .toUpperCase() as LANGUAGES;

    const auth = useAppSelector(state => state.auth);
    const navigate = useNavigate();
    const t = useTranslations();

    useEffect(() => {
        if (!!auth.authData) {
            navigate('/dashboard');
        }
    }, [auth, navigate]);

    const onJoinNowClick = () => {
        navigate('/register');
    };

    return (
        <div className="homepage">
            <div className="homepage-hero">
                <div className="homepage-hero-text">
                    <Title
                        text={t('home.title', language)}
                        size="xxl"
                        color="white"
                    />
                    <TextField size="xl" color="white">
                        {t('home.firstSubtitle', language)}
                    </TextField>
                    <TextField size="xl" color="white">
                        {t('home.secondSubtitle', language)}
                    </TextField>
                    <Button
                        size="sm"
                        text={t('home.joinNow', language)}
                        variant="green"
                        onClick={onJoinNowClick}
                    />
                </div>
            </div>
            <div className="homepage-main">
                <div>
                    <div className="homepage-text">
                        <Title text="Get ready to play" size="xl" />
                        <TextField size="l">
                            Easy to use interface makes finding events a
                            pleasant experience.
                        </TextField>
                        <TextField size="l">Join today!</TextField>
                    </div>
                </div>
                <img
                    className="homepage-image"
                    src={homepageImage2}
                    alt="homepage"
                />
            </div>
            <div className="homepage-main-inverted">
                <img
                    className="homepage-image"
                    src={homepageImage5}
                    alt="homepage"
                />
                <div>
                    <div className="homepage-text">
                        <Title text="A ton of sport" size="xl" />
                        <TextField size="l">
                            Whatever is your favourite sport, with Sportwice you
                            can find events to fuel your passion.
                        </TextField>
                        <TextField size="l">
                            Football, Basketball, Volleyball, Squash, Tennis,
                            you name it!
                        </TextField>
                    </div>

                    <Button
                        size="sm"
                        text={t('home.joinNow', language)}
                        variant="green"
                        onClick={onJoinNowClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default Homepage;
