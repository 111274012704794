import { createSlice } from '@reduxjs/toolkit';
import { EventSearchFiltersState } from '../../utils/types';

const initialState: EventSearchFiltersState = {
    city: null,
    eventType: null,
    startDate: null,
};

export const eventSearchFiltersSlice = createSlice({
    name: 'necessaryData',
    initialState,
    reducers: {
        setCity(state, action) {
            state.city = action.payload;
        },
        setEventType(state, action) {
            state.eventType = action.payload;
        },
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
    },
});

const { actions, reducer } = eventSearchFiltersSlice;

export const { setCity, setEventType, setStartDate } = actions;

export default reducer;
