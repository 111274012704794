import { useMemo, useState } from 'react';
import { fetchSearchResults } from '../../../api/searchBarAPI';
import { OptionType } from '../../../utils/types';
import { SearchBarResult } from './types';
import { renderUserName } from '../../../utils/helpers';

export const useSearchBar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState<SearchBarResult | null>(null);
    const [error, setError] = useState(null);

    const generateOptions = useMemo(() => {
        const userOptions: OptionType[] = [];
        const eventOptions: OptionType[] = [];
        results?.users?.forEach(user => {
            userOptions.push({
                id: user._id,
                label: renderUserName(user.name, user.surname),
                image: user.avatarUrl,
            });
        });
        results?.events?.forEach(eventRecord => {
            eventOptions.push({
                id: eventRecord._id,
                label: eventRecord.name,
                image: eventRecord.owner.avatarUrl,
            });
        });
        return { users: userOptions, events: eventOptions };
    }, [results]);

    const fetchResults = async (searchString: string) => {
        try {
            setIsLoading(true);
            const data = await fetchSearchResults(searchString);
            setResults(data);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    return { isLoading, searchOptions: generateOptions, error, fetchResults };
};
