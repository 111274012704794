import { useNavigate } from 'react-router-dom';
import { IEvent } from '../../../utils/types';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import { Title, TextField } from '../../common';
import { renderUserName } from '../../../utils/helpers';

interface PastEventTeamsProps {
    eventDetails: IEvent;
}

const PastEventTeams = ({ eventDetails }: PastEventTeamsProps) => {
    const navigate = useNavigate();

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    return (
        <div className="event-details-teams">
            {eventDetails.teams.map((team, index) => (
                <div key={team._id} className="vote-event-team">
                    {!eventDetails.isPartial && (
                        <div className="past-event-team-name">
                            <Title
                                text={`Team ${index === 0 ? 'A' : 'B'}`}
                                size="l"
                            />
                        </div>
                    )}
                    <div className="vote-event-team">
                        {team.users.map(user => (
                            <div
                                key={user._id}
                                className="vote-event-user-wrapper"
                            >
                                <div className="vote-event-user">
                                    <UserAvatar
                                        size="l"
                                        imageSrc={user.avatarUrl}
                                        onClick={() =>
                                            userClickHandler(user._id)
                                        }
                                    />
                                    <TextField>
                                        {renderUserName(
                                            user.name,
                                            user.surname
                                        )}
                                    </TextField>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PastEventTeams;
