import { useEffect, useState } from 'react';
import { CreateEventPayload, OptionType } from '../../utils/types';
import { fetchEventDetails, updateEventAPI } from '../../api/events';
import { useNavigate } from 'react-router-dom';

export interface CreateEventFormValues {
    name: string;
    type: string;
    street: string;
    totalPrice: number;
    date: Date | null;
    duration: number;
    isPrivate: boolean;
    maxUsers: number;
    mapsUrl: string;
    ownerNotes: string;
}

interface Errors {
    name: string | null;
    type: string | null;
    street: string | null;
    totalPrice: string | null;
    date: string | null;
    duration: string | null;
    isPrivate: string | null;
    maxUsers: string | null;
}

const defaultFormValues = {
    name: '',
    type: '',
    street: '',
    mapsUrl: '',
    totalPrice: 0,
    date: null,
    duration: 0,
    isPrivate: false,
    isPartial: false,
    maxUsers: 0,
    ownerNotes: '',
};

export const useEditEvent = (eventId: string | undefined) => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [error, setError] = useState(null);

    const [formValues, setFormValues] = useState<CreateEventFormValues>({
        ...defaultFormValues,
    });

    useEffect(() => {
        const fetchEventData = async function () {
            if (eventId) {
                try {
                    const data = await fetchEventDetails(eventId);
                    setFormValues({
                        name: data.name,
                        type: data.type,
                        street: data.street,
                        mapsUrl: data.mapsUrl,
                        totalPrice: data.totalPrice,
                        duration: data.duration,
                        isPrivate: data.isPrivate,
                        // isPartial: false,
                        maxUsers: data.maxUsers,
                        ownerNotes: data.ownerNotes,
                        date: new Date(data.date),
                    });
                } catch (error: any) {
                    console.warn(error);
                    setError(error);
                }
                setIsLoading(false);
            }
        };
        fetchEventData();
    }, [eventId]);

    const [formErrors, setFormErrors] = useState<Errors>({
        name: null,
        type: null,
        street: null,
        totalPrice: null,
        date: null,
        duration: null,
        isPrivate: null,
        maxUsers: null,
    });

    useEffect(() => {
        setFormErrors({
            name: null,
            type: null,
            street: null,
            totalPrice: null,
            date: null,
            duration: null,
            isPrivate: null,
            maxUsers: null,
        });
    }, [formValues]);

    const inputChangeHandler = (
        name: string,
        value: string | boolean | number
    ) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const onRadioChange = (selectedOption: string) => {
        setFormValues({
            ...formValues,
            isPrivate: selectedOption === 'private',
        });
    };

    const onEventTypeChange = (name: string, result: OptionType) => {
        setFormValues({
            ...formValues,
            [name]: result.id,
        });
    };

    const onDateChange = (newDate: Date) => {
        setFormValues({
            ...formValues,
            date: newDate,
        });
    };

    const onSave = async () => {
        let hasErrors = false;
        const formErrorsCopy = { ...formErrors };
        let key: keyof CreateEventFormValues;

        for (key in formValues) {
            if (
                (formValues[key] === null || formValues[key] === '') &&
                key !== 'mapsUrl' &&
                key !== 'ownerNotes'
            ) {
                hasErrors = true;
                formErrorsCopy[key] = 'This field is required';
            }

            if (
                (key === 'maxUsers' || key === 'duration') &&
                formValues[key] === 0
            ) {
                hasErrors = true;
                formErrorsCopy[key] = 'This field is required';
            }

            if (key === 'maxUsers' && Number(formValues[key]) % 2 !== 0) {
                hasErrors = true;
                formErrorsCopy[key] = `Must be an even number`;
            }
            if (
                (key === 'duration' || key === 'totalPrice') &&
                Number(formValues[key]) % 2 !== 0
            ) {
                hasErrors = true;
                formErrors[key] = `Must be a number`;
            }
        }

        if (hasErrors) {
            setFormErrors({
                ...formErrorsCopy,
            });
            return;
        }

        setIsSaveLoading(true);

        try {
            const createEventPayload: CreateEventPayload = {
                ...formValues,
                maxUsers: Number(formValues.maxUsers),
                totalPrice: Number(formValues.totalPrice),
                duration: Number(formValues.duration),
                date: formValues.date?.toISOString()!,
            };
            await updateEventAPI(eventId!, createEventPayload);

            setIsSaveLoading(false);
            navigate(`/events/${eventId}`);
        } catch (error: any) {
            console.warn(error);
            setError(error);
            setIsSaveLoading(false);
        }
    };

    return {
        formValues,
        formErrors,
        inputChangeHandler,
        onEventTypeChange,
        onDateChange,
        onSave,
        onRadioChange,
        isLoading,
        isSaveLoading,
        error,
    };
};
