import { useState } from 'react';
import { Link } from 'react-router-dom';
import { isValidEmail } from '../../utils/helpers';
import { useAppSelector } from '../../store/hooks';
import { TextField, Title, Button, TextInput } from '../common';
import { LANGUAGES } from '../../utils/consts';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { contactUs } from '../../api/contactUs';
import { ContactUsPayload } from '../../utils/types';

import './contactUs.css';

interface LoginFormValues {
    email: string | null;
    title: string | null;
    message: string | null;
}

interface Errors {
    email: string | null;
    title: string | null;
    message: string | null;
}

const ContactUs = () => {
    const auth = useAppSelector(state => state.auth);
    const t = useTranslations();
    const language = navigator.language
        .split('-')[0]
        .toUpperCase() as LANGUAGES;

    const [formValues, setFormValues] = useState<LoginFormValues>({
        email: null,
        title: null,
        message: null,
    });
    const [errors, setErrors] = useState<Errors>({
        email: null,
        title: null,
        message: null,
    });
    const [showThankYou, setShowThankYou] = useState(false);

    const inputChangeHandler = (name: string, value: string) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({
            email: null,
            title: null,
            message: null,
        });
    };

    const onSendMessage = async () => {
        if (
            !formValues.email ||
            (formValues.email && !isValidEmail(formValues.email))
        ) {
            setErrors({ ...errors, email: 'Invalid email address' });
            return;
        }

        if (!formValues.title) {
            setErrors({ ...errors, title: 'This field is required' });
            return;
        }

        if (!formValues.message) {
            setErrors({ ...errors, message: 'This field is required' });
            return;
        }

        try {
            const payload: ContactUsPayload = { ...formValues };
            await contactUs(payload);
            setShowThankYou(true);
        } catch (e) {
            setErrors({ ...errors, email: 'Email not found' });
        }
    };

    return (
        <div className="contact-us">
            {showThankYou ? (
                <div className="contact-us-thank-you">
                    <Title
                        text={t('contactUs.thankYou', language)}
                        size="xl"
                        color="green"
                    />
                    <TextField>
                        {t('contactUs.thankYouSub', language)}
                    </TextField>
                    <Link to="/">
                        <TextField color="green">
                            {t('contactUs.backToHome', language)}
                        </TextField>
                    </Link>
                </div>
            ) : (
                <div className="contact-us-main">
                    <div className="contact-us-header">
                        <Title
                            text={t('contactUs.title', language)}
                            size="xl"
                        />
                        <TextField>
                            {t('contactUs.subtitle', language)}
                        </TextField>
                    </div>
                    <TextInput
                        name="email"
                        label={t('contactUs.email', language)}
                        placeholder={t('login.enterEmail', language)}
                        onChange={inputChangeHandler}
                        value={formValues.email}
                        error={errors.email}
                        className="full-width"
                    />
                    <TextInput
                        name="title"
                        label={t('contactUs.emailTitle', language)}
                        placeholder={t('contactUs.emailTitle', language)}
                        onChange={inputChangeHandler}
                        value={formValues.title}
                        error={errors.title}
                        className="full-width"
                    />
                    <TextInput
                        name="message"
                        label={t('contactUs.message', language)}
                        placeholder={t('contactUs.message', language)}
                        onChange={inputChangeHandler}
                        value={formValues.message}
                        error={errors.message}
                        className="full-width"
                        isTextArea
                    />
                    <Button
                        text={t('contactUs.sendMessage', language)}
                        variant="green"
                        onClick={onSendMessage}
                        disabled={auth.isLoading}
                    />
                </div>
            )}
        </div>
    );
};

export default ContactUs;
