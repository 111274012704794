import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from './hooks/auth/useAuth';
import { useGeoData } from './hooks/geoData/newUseGeoData';
import globalRouter from './utils/globalRouter';
import Header from './components/Header/Header';
import Homepage from './components/Homepage/Homepage';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import RequireAuth from './components/RequireAuth/RequireAuth';
import EventDetails from './components/EventDetails/EventDetails';
import UserPage from './components/UserPage/UserPage';
import FindEvents from './components/FindEvents/FindEvents';
import CreateEvent from './components/CreateEvent/CreateEvent';
import Register from './components/Register/Register';
import UserSettings from './components/UserSettings/UserSettings';
import EditEvent from './components/EditEvent/EditEvent';
import Footer from './components/Footer/Footer';
import ContactUs from './components/ContactUs/ContactUs';

import 'react-datepicker/dist/react-datepicker.css';
import ResetPassword from './components/ResetPassword/ResetPassword';
import CreditsPage from './components/CreditsPage/CreditsPage';
import NotFound from './components/NotFound/NotFound';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: 'auto',
        });
    }, [pathname]);

    return null;
}

function App() {
    const auth = useAuth();
    const geoData = useGeoData();
    const navigate = useNavigate();
    globalRouter.navigate = navigate;

    // useEffect(() => {
    //     document.title = 'Eventuos - Find sport events in your city';
    // }, []);

    if (auth.meIsLoading || geoData.isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="App">
            <Header />
            <ScrollToTop />
            <div className="app-container">
                <main>
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route
                            path="/reset-password"
                            element={<ResetPassword />}
                        />
                        <Route path="/contact-us" element={<ContactUs />} />
                        <Route path="/credits" element={<CreditsPage />} />
                        <Route
                            path="/dashboard"
                            element={
                                <RequireAuth>
                                    <Dashboard />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/events/:eventId"
                            element={
                                <RequireAuth>
                                    <EventDetails />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/events/:eventId/edit"
                            element={
                                <RequireAuth>
                                    <EditEvent />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/users/:userId"
                            element={
                                <RequireAuth>
                                    <UserPage />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/find"
                            element={
                                <RequireAuth>
                                    <FindEvents />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/create"
                            element={
                                <RequireAuth>
                                    <CreateEvent />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <RequireAuth>
                                    <UserSettings />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/not-found"
                            element={
                                <RequireAuth>
                                    <NotFound />
                                </RequireAuth>
                            }
                        />
                    </Routes>
                </main>
            </div>
            <Footer />
        </div>
    );
}

export default App;
