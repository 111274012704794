import { TextField, Title } from '../common';

import './creditsPage.css';

export default function CreditsPage() {
    return (
        <div className="credits-page">
            <Title size="xl" text="Credits" />
            <div className="credits-page-icons">
                <Title
                    size="l"
                    text="Special thanks for the great icons to (links below):"
                />
                <ul>
                    <li>
                        <a
                            href="https://www.flaticon.com/free-icons/squash"
                            title="squash icons"
                        >
                            <TextField>
                                Squash icons created by smashingstocks -
                                Flaticon
                            </TextField>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.flaticon.com/free-icons/volleyball"
                            title="volleyball icons"
                        >
                            <TextField>
                                Volleyball icons created by Freepik - Flaticon
                            </TextField>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.flaticon.com/free-icons/tennis"
                            title="tennis icons"
                        >
                            <TextField>
                                Tennis icons created by imaginationlol -
                                Flaticon
                            </TextField>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.flaticon.com/free-icons/sport"
                            title="sport icons"
                        >
                            <TextField>
                                Sport icons created by mavadee - Flaticon
                            </TextField>
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.flaticon.com/free-icons/basketball"
                            title="basketball icons"
                        >
                            <TextField>
                                Basketball icons created by Bharat Icons -
                                Flaticon
                            </TextField>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}
