import { LANGUAGES } from './consts';

export interface DashboardState {
    isLoading: boolean;
    upcomingEvents: EventType[];
}

export interface RegisterPayload {
    name: string | null;
    surname: string | null;
    email: string | null;
    password: string | null;
    country: string | null;
    city: string | null;
}

export interface CompleteUserProfilePayload {
    country: string | null;
    city: string | null;
}

export interface UpdateInfoPayload {
    country: string | null;
    city: string | null;
    language: LANGUAGES | null;
}

export interface ContactUsPayload {
    email: string | null;
    title: string | null;
    message: string | null;
}

export interface AuthResponse {
    data: Auth;
    token: string;
}

export interface Auth {
    _id: string;
    name: string;
    surname: string;
    username: string;
    avatarUrl: string | null;
    language: LANGUAGES;
    city: string;
    country: string;
    countryCode: string;
    currency: string;
    stats: Stats;
    createdAt: string;
    email: string;
    notifications: INotification[];
    reports: IReport;
}

export interface AuthState {
    isLoading: boolean;
    meIsLoading: boolean;
    error: any;
    authData: Auth | null;
    wasUserFetched: boolean;
}

export interface City {
    _id: string;
    countryCode: string;
    name: string;
}

export interface GeoDataState {
    isLoading: boolean;
    error: any;
    cities: City[] | null;
}

export interface INotification {
    status: NotificationStatus;
    _id: string;
    sender: Auth;
    event: string;
    text: string;
    date: Date;
}

export enum NotificationStatus {
    UNREAD = 'UNREAD',
    READ = 'READ',
}

export enum EVENT_TYPES {
    FIVE_SIDE_FOOTBALL = 'FIVE_SIDE_FOOTBALL',
    SEVEN_SIDE_FOOTBALL = 'SEVEN_SIDE_FOOTBALL',
    BASKETBALL = 'BASKETBALL',
    THREE_SIDE_VOLLEYBALL = 'THREE_SIDE_VOLLEYBALL',
    FIVE_SIDE_VOLLEYBALL = 'FIVE_SIDE_VOLLEYBALL',
}

export interface EventType {
    id: string;
    type: EVENT_TYPES;
    maxUsers: number;
}

export interface NecessaryDataState {
    isLoading: boolean;
    data: NecessaryData | null;
}

export interface EventSearchFiltersState {
    city: string | null;
    eventType: string | null;
    startDate: Date | null;
}

export interface NecessaryData {
    eventTypes: EventType[];
}

export interface OptionType {
    id: number | string;
    label: string;
    image?: string | null;
}

export interface ITeam {
    _id: string;
    users: Auth[];
}

export interface UnpopulatedTeam {
    id: string;
    users: string[];
}

export interface IJoinRequest {
    _id: string;
    status: JOIN_REQUEST_STATUS;
    user: Auth;
}

export interface ILike {
    user: string;
    likesCount: number;
}

export interface IReport {
    count: number;
    lastReportDate: Date;
}

export interface IBoardMessage {
    _id: string;
    user: Auth;
    date: Date;
    message: string;
}

export interface IEvent {
    _id: string;
    name: string;
    owner: Auth;
    isPrivate: boolean;
    isPartial: boolean;
    mapsUrl: string;
    ownerNotes: string;
    type: EventTypes;
    joinedUsers: number;
    maxUsers: number;
    date: string;
    duration: number;
    city: string;
    street: string;
    totalPrice: number;
    currency: string;
    teams: ITeam[];
    joinRequests: IJoinRequest[];
    isClosed: boolean;
    isCancelled: boolean;
    isVotingPhase: boolean;
    votingPhaseEndDate: string | null;
    likes: ILike[];
    reports: string[];
    winner: string | null;
    voters: string[];
    mvp: string;
    board: IBoardMessage[];
}

export interface UnpopulatedEvent {
    _id: string;
    name: string;
    owner: Auth;
    isPrivate: boolean;
    mapsUrl: string;
    type: EventTypes;
    joinedUsers: number;
    maxUsers: number;
    date: string;
    city: string;
    street: string;
    totalPrice: number;
    currency: string;
    teams: UnpopulatedTeam[];
    joinRequests: IJoinRequest[];
    isClosed: boolean;
    isCancelled: boolean;
    isVotingPhase: boolean;
    votingPhaseEndDate: string | null;
    likes: ILike[];
    winner: string | null;
    voters: string[];
    mvp: string;
}

export interface Stats {
    eventsJoined: number;
    eventsWon: number;
    eventsLost: number;
    totalPrice: number;
    currency: string;
    likesReceived: number;
    dislikeReceived: number;
    mvpReceived: number;
}

export interface UserSearchResult {
    id: string;
    username: string;
    avatarUrl: string | null;
}

export enum EventTypes {
    FOOTBALL = 'FOOTBALL',
    BASKETBALL = 'BASKETBALL',
    VOLLEYBALL = 'VOLLEYBALL',
    SQUASH = 'SQUASH',
    TENNIS = 'TENNIS',
}

export enum JOIN_REQUEST_STATUS {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export interface Stats {
    likes: number;
    appearances: number;
    victories: number;
    defeats: number;
    loss: number;
    mvp: number;
    organized: number;
}

export interface Country {
    code: string;
    currency: string;
    name: string;
}

export interface User {
    _id: string;
    username: string;
    name: string;
    surname: string;
    avatarUrl: string | null;
    city: string;
    country: string;
    countryCode: string;
    currency: string;
    stats: Stats;
    createdAt: string;
    email: string;
    notifications: INotification[];
    reports: IReport;
}

export enum EventHistoryTypes {
    EVENT_JOIN = 'EVENT_JOIN',
    EVENT_LEAVE = 'EVENT_LEAVE',
    // TODO: EVENT_WIN ??
}

export interface CountriesResponse {
    id: string;
    code: string;
    name: string;
    currency: string;
}

export interface CitiesResponse {
    name: string;
    countryCode: string;
    stateCode: string;
    latitude: string;
    longitude: string;
}

export interface CreateEventPayload {
    name: string;
    type: string;
    street: string;
    totalPrice: number;
    date: string;
    isPrivate: boolean;
    maxUsers: number;
    duration: number;
    mapsUrl: string | null;
    ownerNotes: string | null;
}
