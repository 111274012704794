import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import eventSearchFiltersReducer from './eventSearchFilters/eventSearchFilters';
import geoDataReducer from './geoData/geoDataSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        eventSearchFilters: eventSearchFiltersReducer,
        geoData: geoDataReducer,
    },
});

export const mockStore = configureStore({
    reducer: {
        auth: authReducer,
        eventSearchFilters: eventSearchFiltersReducer,
        geoData: geoDataReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
