import { useState } from 'react';
import EventDetailsInfo from '../EventDetailsInfo/EventDetailsInfo';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import PastEventTeams from './PastEventTeams';
import { IEvent } from '../../../utils/types';
import { Button, Dialog, Icon, Tabs, TextField, Title } from '../../common';
import EventBoard from '../EventBoard/EventBoard';
import { COLOURS, eventTypeMap } from '../../../utils/consts';
import PastEventDialog from './PastEventDialog';

import './pastEvent.css';

const sections = ['Details', 'Board'];

interface PastEventProps {
    eventDetails: IEvent;
    onNewBoardMessage: (event: IEvent) => void;
    onCancelEvent: () => void;
    onStartVotingPhase: () => void;
    isOwner: boolean;
}

const PastEvent = ({
    eventDetails,
    onNewBoardMessage,
    isOwner,
    onCancelEvent,
    onStartVotingPhase,
}: PastEventProps) => {
    const [selectedTab, setSelectedTab] = useState('Details');
    const [isDialogOpen, setIsDialogOpen] = useState(
        isOwner && eventDetails.joinedUsers < eventDetails.maxUsers
    );
    const [isVoteDialogOpen, setIsVoteDialogOpen] = useState(
        isOwner && eventDetails.joinedUsers === eventDetails.maxUsers
    );

    const onChangeTab = (tab: string) => {
        setSelectedTab(tab);
    };

    const showCancelButton =
        isOwner && eventDetails.joinedUsers < eventDetails.maxUsers;
    return (
        <div className="past-event">
            <div className="past-event-header">
                <UserAvatar size="xl" imageSrc={eventDetails.owner.avatarUrl} />
                <div className="past-event-subheader">
                    <div className="past-event-info-wrapper">
                        <Title
                            text={`${eventTypeMap[eventDetails.type]} - ${
                                eventDetails.name
                            }`}
                            size="xl"
                        />

                        <TextField size="m">{`${eventDetails.owner.name} ${eventDetails.owner.surname}`}</TextField>
                        {!isOwner && (
                            <div className="flex gap">
                                <Icon name="bx-error" color={COLOURS.RED} />
                                <TextField>
                                    Owner hasn't started the vote yet
                                </TextField>
                            </div>
                        )}
                    </div>
                    {isOwner && (
                        <div className="past-event-actions">
                            <Button
                                onClick={onStartVotingPhase}
                                text="Start vote"
                                variant="success"
                            />
                            {showCancelButton && (
                                <Button
                                    onClick={onCancelEvent}
                                    text="Cancel event"
                                    variant="warning"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className="event-details-main">
                <Tabs
                    sections={sections}
                    selectedTab={selectedTab}
                    onChangeTab={onChangeTab}
                />
                {selectedTab === 'Details' && (
                    <div className="event-details-section">
                        <EventDetailsInfo eventDetails={eventDetails} />
                        <PastEventTeams eventDetails={eventDetails} />
                    </div>
                )}
                {selectedTab === 'Board' && (
                    <div className="event-details-section">
                        <EventBoard
                            board={eventDetails.board}
                            onNewBoardMessage={onNewBoardMessage}
                        />
                    </div>
                )}
            </div>
            <PastEventDialog
                isOpen={isDialogOpen}
                eventRecord={eventDetails}
                onCancelEvent={onCancelEvent}
                onStartVote={onStartVotingPhase}
                onClose={() => setIsDialogOpen(false)}
            />
            <Dialog
                isOpen={isVoteDialogOpen}
                title="Start voting phase?"
                bodyText="Do you want to start the voting phase for this event?"
                onClose={() => setIsVoteDialogOpen(false)}
                onConfirm={onStartVotingPhase}
            />
        </div>
    );
};

export default PastEvent;
