import { Button, Title, TextField, Icon } from '../../common';
import { IEvent } from '../../../utils/types';
import { COLOURS } from '../../../utils/consts';

interface DialogProps {
    isOpen: boolean;
    eventRecord: IEvent | null;
    onCancelEvent: () => void;
    onStartVote: () => void;
    onClose: () => void;
}

export default function PastEventDialog({
    isOpen,
    eventRecord,
    onCancelEvent,
    onStartVote,
    onClose,
}: DialogProps) {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="dialog-overlay">
            <div className="dialog-container">
                <div className="dialog-header green">
                    <Icon
                        name="bx-x"
                        color={COLOURS.GREY}
                        size="30px"
                        cursor="pointer"
                        onClick={onClose}
                    />
                    <Title text="Action required"></Title>
                </div>
                <div className="dialog-body flex gap">
                    <TextField>{`Looks like you expected ${eventRecord?.maxUsers} people to join your event but only ${eventRecord?.joinedUsers} joined.`}</TextField>
                    <TextField>{`Do you want to cancel the event?`}</TextField>
                </div>
                <div className="dialog-footer">
                    <Button
                        type="button"
                        text="Yes, cancel the event"
                        onClick={onCancelEvent}
                        size="lg"
                        variant="warning"
                    />
                    <Button
                        type="button"
                        text="No, start voting session"
                        variant="success"
                        onClick={onStartVote}
                        size="lg"
                    />
                </div>
            </div>
        </div>
    );
}
