import { useNavigate } from 'react-router-dom';
import { format, isBefore, parseISO } from 'date-fns';
import { UnpopulatedEvent } from '../../../utils/types';
import UserAvatar from '../UserAvatar/UserAvatar';
import { COLOURS, eventTypeMap } from '../../../utils/consts';
import { useTranslations } from '../../../hooks/translations/useTranslations';
import { Icon, TextField, Title } from '..';

import './eventRow.css';

interface EventRowProps {
    isListView?: boolean;
    eventRecord: UnpopulatedEvent;
    isWinner?: boolean;
    isMvp?: boolean;
    showVoteEndDate?: boolean;
    actionRequired?: boolean;
    isUserPage?: boolean;
}

const EventRow: React.FC<EventRowProps> = ({
    eventRecord,
    isListView,
    isWinner,
    isMvp,
    showVoteEndDate,
    actionRequired = false,
    isUserPage = false,
}) => {
    const navigate = useNavigate();
    const t = useTranslations();

    const eventClickHandler = (eventId: string) => {
        navigate(`/events/${eventId}`);
    };

    const eventDate = isListView
        ? format(new Date(eventRecord.date), 'dd/MM/yyyy, HH:mm')
        : format(new Date(eventRecord.date), 'dd/MM/yyyy');

    const voteEndDate = eventRecord.votingPhaseEndDate
        ? format(new Date(eventRecord.votingPhaseEndDate), 'dd/MM/yyyy, HH:mm')
        : null;

    const eventPrice =
        (eventRecord.totalPrice / eventRecord.maxUsers) % 1 !== 0
            ? (eventRecord.totalPrice / eventRecord.maxUsers).toFixed(1)
            : eventRecord.totalPrice / eventRecord.maxUsers;

    const usersJoined = eventRecord.teams
        ? eventRecord.teams[0].users.length + eventRecord.teams[1].users.length
        : null;

    const isPastEvent = isBefore(parseISO(eventRecord.date), new Date());

    return (
        <div
            className={`event-row clickable ${
                isPastEvent &&
                !actionRequired &&
                !showVoteEndDate &&
                !isUserPage
                    ? 'disabled'
                    : ''
            }`}
            onClick={() => eventClickHandler(eventRecord._id)}
        >
            <UserAvatar imageSrc={eventRecord.owner.avatarUrl} size="xl" />
            <div className="event-row-record">
                <div className="event-row-record-header">
                    <div className="event-row-title">
                        <Icon name={`bx-${eventRecord.type.toLowerCase()}`} />
                        <Title
                            text={`${eventTypeMap[eventRecord.type]} - ${
                                eventRecord.name
                            }`}
                            size="m"
                        />
                    </div>

                    <div className="event-row-icons">
                        {isListView ? (
                            <>
                                <Icon name="bx-user-check" />
                                <TextField color="green">
                                    {`${usersJoined}/${eventRecord.maxUsers}`}
                                </TextField>

                                {isPastEvent && (
                                    <>
                                        <Icon
                                            name="bx-error"
                                            color={COLOURS.RED}
                                        />
                                        <TextField color="red">
                                            {t('common.eventExpired')}
                                        </TextField>
                                    </>
                                )}
                            </>
                        ) : showVoteEndDate ? (
                            <TextField color="green">{`${t(
                                'common.voteEnds'
                            )} ${voteEndDate}`}</TextField>
                        ) : actionRequired ? (
                            <>
                                <Icon name="bx-user-check" />
                                <TextField color="green">
                                    {`${usersJoined}/${eventRecord.maxUsers}`}
                                </TextField>
                                <Icon name="bx-error" color={COLOURS.RED} />
                                <TextField color="red">
                                    {t('common.actionRequired')}
                                </TextField>
                            </>
                        ) : (
                            <>
                                {isWinner && (
                                    <Icon name="bx-trophy" color="#333332" />
                                )}
                                {isMvp && (
                                    <Icon name="bx-star" color="#333332" />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="event-row-record-details">
                    <TextField size="s">
                        {eventRecord.street}, {eventRecord.city}
                    </TextField>
                    <div>
                        <TextField>{eventDate}</TextField>
                        {isListView && (
                            <TextField>{`${eventPrice} ${eventRecord.currency}`}</TextField>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventRow;
