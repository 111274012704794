import { Icon, TextField, Title } from '..';
import { useTranslations } from '../../../hooks/translations/useTranslations';
import { COLOURS } from '../../../utils/consts';

import './genericError.css';

const GenericError = () => {
    const t = useTranslations();

    return (
        <div className="generic-error">
            <Icon name="bx-error" color={COLOURS.RED} size="50px" />
            <Title text={t('errors.genericErrorTitle')} size="l" />
            <TextField>{t('errors.genericError')}</TextField>
        </div>
    );
};

export default GenericError;
