import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import mockRequest from '../../utils/mockRequest';
import {
    AuthState,
    AuthResponse,
    User,
    RegisterPayload,
    UpdateInfoPayload,
} from '../../utils/types';
import { RootState } from '../store';

const initialState: AuthState = {
    isLoading: false,
    meIsLoading: true,
    error: null,
    authData: null,
    wasUserFetched: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authLoading(state) {
            state.isLoading = true;
            state.wasUserFetched = false;
        },
        authSuccess(state, action) {
            state.isLoading = false;
            state.authData = action.payload;
            state.wasUserFetched = true;
        },
        authError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        refreshCurrentUser(state, action) {
            state.authData = action.payload.data;
        },
        clearCurrentUser(state) {
            state.authData = null;
        },
    },
    extraReducers(builder) {
        builder.addCase(login.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(
            login.fulfilled,
            (state, action: PayloadAction<AuthResponse>) => {
                state.authData = action.payload.data;
                state.isLoading = false;
                // localStorage.setItem('jwt', action.payload.token);
            }
        );
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
        builder.addCase(register.pending, state => {
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(register.fulfilled, (state, action) => {
            state.authData = action.payload.data;
            state.isLoading = false;
            localStorage.setItem('jwt', action.payload.token);
        });
        builder.addCase(register.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
        builder.addCase(getCurrentUser.pending, (state, action) => {
            state.meIsLoading = true;
        });
        builder.addCase(getCurrentUser.rejected, (state, action) => {
            state.meIsLoading = false;
            state.error = 'Not found';
            state.authData = null;
        });
        builder.addCase(
            getCurrentUser.fulfilled,
            (state, action: PayloadAction<AuthResponse>) => {
                state.authData = action.payload.data;
                state.meIsLoading = false;
                state.wasUserFetched = true;
            }
        );
        builder.addCase(saveUserAvatarUrl.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(saveUserAvatarUrl.rejected, (state, action) => {
            state.isLoading = false;
            state.error = 'Not found';
        });
        builder.addCase(
            saveUserAvatarUrl.fulfilled,
            (state, action: PayloadAction<AuthResponse>) => {
                state.authData = action.payload.data;
                state.isLoading = false;
            }
        );
        builder.addCase(saveUserInfo.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(saveUserInfo.rejected, (state, action) => {
            state.isLoading = false;
            state.error = 'Not found';
        });
        builder.addCase(
            saveUserInfo.fulfilled,
            (state, action: PayloadAction<AuthResponse>) => {
                state.authData = action.payload.data;
                state.isLoading = false;
            }
        );
        builder.addCase(logout.pending, (state, action) => {
            state.meIsLoading = true;
        });
        builder.addCase(logout.fulfilled, state => {
            state.meIsLoading = false;
            state.authData = null;
        });
    },
});

// Action creators are generated for each case reducer function
export const {
    authLoading,
    authSuccess,
    authError,
    refreshCurrentUser,
    clearCurrentUser,
} = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;

// Define a thunk that dispatches those action creators
// export const getCurrentUser = () => async (dispatch: AppDispatch) => {
//     dispatch(authLoading());
//     try {
//         const response = await mockRequest.get('auth');

//         dispatch(authSuccess(response.data.authData));
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             dispatch(authError(error.message));
//         } else {
//             dispatch(authError('An unexpected error occurred'));
//         }
//     }
// };

export const getCurrentUser = createAsyncThunk('auth/me', async () => {
    const response = await mockRequest.get('/auth/me');
    if (!response.data) {
        throw new Error('response was not ok');
    }
    return response.data;
});

export const saveUserAvatarUrl = createAsyncThunk(
    'auth/save-image',
    async (imageUrl: string | null) => {
        const response = await mockRequest.post('/users/save-image', {
            imageUrl,
        });
        if (!response.data) {
            throw new Error('response was not ok');
        }
        return response.data;
    }
);

export const saveUserInfo = createAsyncThunk(
    'auth/save-info',
    async (userInfo: UpdateInfoPayload) => {
        const response = await mockRequest.patch('/auth/me', userInfo);
        if (!response.data) {
            throw new Error('response was not ok');
        }
        return response.data;
    }
);

// export const login = (user: User) => async (dispatch: AppDispatch) => {
//     dispatch(authLoading());
//     try {
//         const response = await mockRequest.post('auth/login', user);
//         dispatch(authSuccess(response.data));
//     } catch (error) {
//         dispatch(authError(error));
//     }
// };

export const register = createAsyncThunk(
    'auth/register',
    async (user: RegisterPayload) => {
        const response = await mockRequest.post('/users/register', user);

        return response.data;
    }
);

export const login = createAsyncThunk('auth/login', async (user: User) => {
    const response = await mockRequest.post('/users/login', user);

    return response.data;
});

export const logout = createAsyncThunk('/auth/logout', async () => {
    await mockRequest.get('/auth/logout');
    return;
});

export default authSlice.reducer;
