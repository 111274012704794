import { Link } from 'react-router-dom';
import { TextField, Title } from '../common';
import { useTranslations } from '../../hooks/translations/useTranslations';

import './footer.css';

const Footer = () => {
    const t = useTranslations();

    return (
        <footer className="footer">
            <div className="inner-footer">
                <div>
                    <Title text="Sportwice" size="l" />
                </div>
                <div className="footer-right">
                    <Link to="/">
                        <TextField size="l">Home</TextField>
                    </Link>
                    <Link to="/credits">
                        <TextField size="l">Credits</TextField>
                    </Link>
                    <Link to="/contact-us">
                        <TextField size="l">{t('common.contactUs')}</TextField>
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
