import { useNavigate } from 'react-router-dom';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import { SearchOptions } from './types';
import { Title, TextField } from '../../common';

interface SearchBarOptionsProps {
    error?: string | null;
    isLoading: boolean;
    results: SearchOptions | null;
    inputValue: string;
}

const SearchBarOptions = ({
    error,
    isLoading,
    results,
    inputValue,
}: SearchBarOptionsProps) => {
    const navigate = useNavigate();

    const onEventClick = (eventId: string) => {
        navigate(`/events/${eventId}`);
    };

    const onUserClick = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    const noResults =
        results && results.users.length < 1 && results.events.length < 1;

    if (error) return null;

    if (!results) return null;

    if (isLoading) {
        return <div className="search-bar-menu-option">Searching...</div>;
    }

    if (noResults) {
        return (
            <div className="search-bar-menu-option">
                No Results found for {inputValue}
            </div>
        );
    }

    return (
        <>
            {results?.users.length > 0 && (
                <div className="search-bar-menu-options-wrapper">
                    <Title text="Users" size="s" />
                    {results?.users.map((result, index) => (
                        <div
                            className="search-bar-menu-option"
                            key={`${result.id}_${index}`}
                            onClick={() => onUserClick(result.id as string)}
                            role="button"
                            tabIndex={0}
                        >
                            <UserAvatar imageSrc={result.image} size="m" />
                            <TextField>{result.label}</TextField>
                        </div>
                    ))}
                </div>
            )}
            {results?.events.length > 0 && (
                <div className="search-bar-menu-options-wrapper">
                    <Title text="Events" size="s" />
                    {results?.events.map((result, index) => (
                        <div
                            className="search-bar-menu-option"
                            key={`${result.id}_${index}`}
                            onClick={() => onEventClick(result.id as string)}
                            role="button"
                            tabIndex={0}
                        >
                            <UserAvatar imageSrc={result.image} size="m" />
                            <TextField>{result.label}</TextField>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default SearchBarOptions;
