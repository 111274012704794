export enum EVENT_TYPES {
    FOOTBALL = 'FOOTBALL',
    BASKETBALL = 'BASKETBALL',
    VOLLEYBALL = 'VOLLEYBALL',
    SQUASH = 'SQUASH',
    TENNIS = 'TENNIS',
}

export const eventTypeMap = {
    FOOTBALL: 'Football',
    BASKETBALL: 'Basketball',
    VOLLEYBALL: 'Volleyball',
    SQUASH: 'Squash',
    TENNIS: 'Tennis',
};

export enum LANGUAGES {
    EN = 'EN',
    PL = 'PL',
    IT = 'IT',
}

export const languageMap = {
    EN: 'English',
    PL: 'Polski',
    IT: 'Italiano',
};

export const eventTypeOptions = [
    { id: EVENT_TYPES.FOOTBALL, label: eventTypeMap.FOOTBALL },
    { id: EVENT_TYPES.BASKETBALL, label: eventTypeMap.BASKETBALL },
    { id: EVENT_TYPES.VOLLEYBALL, label: eventTypeMap.VOLLEYBALL },
    { id: EVENT_TYPES.SQUASH, label: eventTypeMap.SQUASH },
    { id: EVENT_TYPES.TENNIS, label: eventTypeMap.TENNIS },
];

export const languageOptions = [
    { id: LANGUAGES.EN, label: languageMap.EN },
    { id: LANGUAGES.PL, label: languageMap.PL },
    { id: LANGUAGES.IT, label: languageMap.IT },
];

export const COLOURS = {
    BLACK: '#0d120d',
    GREEN: '#329f5b',
    RED: '#eb4034',
    GREY: '#919191',
    NAVY_BLUE: '#1f3b55',
};
