import { useState } from 'react';
import SearchBarInput from './SearchBarInput';

import './searchBar.css';
import { useSearchBar } from './useSearchBar';
import { COLOURS } from '../../../utils/consts';
import { Icon } from '../../common';

const SearchBar = () => {
    const { isLoading, searchOptions, error, fetchResults } = useSearchBar();

    const [showSearchBar, setShowSearchBar] = useState(false);

    return (
        <div className="search-bar">
            {showSearchBar ? (
                <div className="search-bar-container">
                    <SearchBarInput
                        startSearch={fetchResults}
                        results={searchOptions}
                        isLoading={isLoading}
                        error={error}
                        placeholder="Search users, events..."
                        onCloseClick={() => setShowSearchBar(false)}
                    />
                </div>
            ) : (
                <Icon
                    name="bx-search"
                    color={COLOURS.BLACK}
                    cursor="pointer"
                    size="22px"
                    onClick={() => setShowSearchBar(true)}
                />
            )}
        </div>
    );
};

export default SearchBar;
