import React, { useState, useMemo, useRef } from 'react';
import { OptionType } from '../../../utils/types';

import './select.css';
import { Icon, TextField, Title } from '..';

type SelectProps = {
    options: OptionType[];
    name: string;
    onSelect: (name: string, option: OptionType) => void;
    value?: number | string;
    label?: string;
    placeholder?: string;
    error?: string | null;
    className?: string;
    isDisabled?: boolean;
};

const Select = ({
    options,
    name,
    onSelect,
    value,
    label,
    placeholder,
    error,
    className,
    isDisabled = false,
}: SelectProps) => {
    const [showOptions, setShowOptions] = useState(false);
    const elementId = `select-${name}`;
    const list = useRef<any>(null);

    // useEffect(() => {
    //     if (list.current) {
    //         const element = list.current.querySelector('#selected-option');
    //         element?.scrollIntoView({ behavior: 'instant', block: 'start' });
    //     }
    // }, [showOptions]);

    const scrollToElement = (event: any) => {
        if (list.current) {
            const elements = list.current.querySelectorAll('.select-option');
            const array = Array.from(elements);
            const element: any = array.find((el: any) =>
                el.innerText.toLowerCase().startsWith(event.key)
            );

            element?.scrollIntoView({ behavior: 'instant', block: 'center' });
        }
    };

    const selectedOption = useMemo(
        () => options.find(op => op.id === value)?.label,
        [options, value]
    );

    const showOptionsHandler = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        setShowOptions(!showOptions);
    };

    const closeOptionsHandler = (
        event: React.FocusEvent<HTMLButtonElement>
    ) => {
        if (event.currentTarget === event.target) {
            setShowOptions(false);
        }
    };

    const handleOnSelect = (option: OptionType) => {
        onSelect(name, option);
        setShowOptions(false);
    };

    const renderOptions = () => {
        if (options.length < 1) {
            return null;
        }
        return (
            <div className="select-menu" ref={list}>
                {options.map((option, index) => (
                    <div
                        className="select-option"
                        key={`${option.id}_${index}`}
                        onClick={() => handleOnSelect(option)}
                    >
                        <TextField size="s">{option.label}</TextField>
                        {option.id === value && (
                            <Icon
                                name="bx-check"
                                color="#5b956d"
                                id="selected-option"
                            />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={`select-input-container ${className}`} id={elementId}>
            {label && (
                <label>
                    <Title text={label} size="s" />
                </label>
            )}

            <button
                className="select-input clickable"
                onClick={showOptionsHandler}
                onBlur={closeOptionsHandler}
                onKeyDown={scrollToElement}
                disabled={isDisabled}
            >
                <>
                    {selectedOption || (
                        <span className="select-placeholder">
                            {placeholder || 'Select...'}
                        </span>
                    )}
                    <Icon
                        name="bx-caret-down"
                        color="#dbdde0"
                        cursor="pointer"
                    />
                    {showOptions && renderOptions()}
                </>
            </button>
            {error && <span className="error-message">{error}</span>}
        </div>
    );
};

export default Select;
