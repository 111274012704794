import React, { useRef, useState } from 'react';
import TextInput from '../../common/TextInput/TextInput';
import SearchBarOptions from './SearchBarOptions';
import { SearchOptions } from './types';

import './searchBar.css';
import { Icon } from '../../common';
import { COLOURS } from '../../../utils/consts';

type SearchBarInputProps = {
    error?: string | null;
    placeholder?: string;
    startSearch: (value: string) => void;
    isLoading: boolean;
    results: SearchOptions | null;
    isDisabled?: boolean;
    onCloseClick: () => void;
};

const SearchBarInput = ({
    placeholder = '',
    error = null,
    startSearch,
    isLoading = false,
    results,
    isDisabled = false,
    onCloseClick,
}: SearchBarInputProps) => {
    const [inputValue, setInputValue] = useState('');
    const [openMenu, setOpenMenu] = useState(false);
    const searchMenu = useRef<HTMLElement | null>();

    const showOptionsHandler = (event: any) => {
        event.preventDefault();
        event.stopPropagation();

        if (inputValue) {
            setOpenMenu(!openMenu);
            startSearch(inputValue);
        }
    };

    const closeMenu = (_: React.FocusEvent<HTMLButtonElement>) => {
        setTimeout(() => {
            if (searchMenu.current) {
                setOpenMenu(false);
            }
        }, 100);
    };

    const inputChangeHandler = (_: string, value: string) => {
        let openMenu = false;
        if (value) {
            openMenu = true;
            startSearch(value);
        }
        setInputValue(value);
        setOpenMenu(openMenu);
    };

    return (
        <>
            <button
                className="search-bar-container"
                onBlur={closeMenu}
                onClick={showOptionsHandler}
                ref={element => {
                    searchMenu.current = element;
                }}
            >
                <TextInput
                    icon={
                        <Icon
                            name="bx-search"
                            color={COLOURS.GREY}
                            size="20px"
                        />
                    }
                    value={inputValue}
                    type="text"
                    onChange={inputChangeHandler}
                    name="searchBarInput"
                    placeholder={placeholder}
                    isDisabled={isDisabled}
                    autoComplete="off"
                    withCloseIcon
                    onCloseClick={onCloseClick}
                />
                {openMenu && (
                    <div className="search-bar-menu">
                        <SearchBarOptions
                            error={error}
                            isLoading={isLoading}
                            results={results}
                            inputValue={inputValue}
                        />
                    </div>
                )}
            </button>
            {error && <span className="error-message">{error}</span>}
        </>
    );
};

export default SearchBarInput;
