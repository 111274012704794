import { useAppSelector } from '../../store/hooks';
import {
    Select,
    Button,
    TextInput,
    RadioButton,
    GenericError,
} from '../common';
import { useCreateEvent } from './useCreateEvent';
import CustomDatePicker from '../common/CustomDatePicker/CustomDatePicker';
import { eventTypeOptions } from '../../utils/consts';
import { useTranslations } from '../../hooks/translations/useTranslations';

import './createEvent.css';

const CreateEvent = () => {
    const {
        formValues,
        errors,
        inputChangeHandler,
        onEventTypeChange,
        onDateChange,
        onSave,
        onRadioChange,
        serverError,
        isLoading,
    } = useCreateEvent();
    const auth = useAppSelector(state => state.auth);
    const t = useTranslations();

    if (serverError) {
        return <GenericError />;
    }

    return (
        <div className="flex justify-center">
            <form onSubmit={onSave} className="create-event-form">
                <RadioButton
                    label={t('createEvent.partialEventTitle')}
                    checked={formValues.isPartial}
                    onChange={onRadioChange}
                    options={[
                        {
                            name: 'full',
                            tooltip: t('createEvent.fullEvent'),
                        },
                        {
                            name: 'partial',
                            tooltip: t('createEvent.partialEvent'),
                        },
                    ]}
                />
                <RadioButton
                    label={t('createEvent.publicEventTitle')}
                    checked={formValues.isPrivate}
                    onChange={onRadioChange}
                    options={[
                        {
                            name: 'public',
                            tooltip: t('createEvent.publicEvent'),
                        },
                        {
                            name: 'private',
                            tooltip: t('createEvent.privateEvent'),
                        },
                    ]}
                />
                <TextInput
                    name="name"
                    placeholder={t('createEvent.namePlaceholder')}
                    label={t('createEvent.name')}
                    onChange={inputChangeHandler}
                    value={formValues.name}
                    error={errors.name}
                    className="full-width"
                />
                <Select
                    name="type"
                    options={eventTypeOptions}
                    onSelect={onEventTypeChange}
                    value={formValues.type as string}
                    label="Type"
                    className="full-width"
                    error={errors.type}
                />
                <TextInput
                    value={formValues.maxUsers}
                    name="maxUsers"
                    label={t('createEvent.totalNumber')}
                    onChange={inputChangeHandler}
                    className="full-width"
                    error={errors.maxUsers}
                />
                <TextInput
                    value={auth.authData?.city || ''}
                    name="city"
                    label="City"
                    isDisabled
                    onChange={() => null}
                    className="full-width"
                />
                <TextInput
                    value={formValues.street}
                    name="street"
                    label={t('createEvent.street')}
                    placeholder={t('createEvent.street')}
                    onChange={inputChangeHandler}
                    error={errors.street}
                    className="full-width"
                />
                <TextInput
                    value={formValues.mapsUrl}
                    name="mapsUrl"
                    label={t('createEvent.mapsLink')}
                    placeholder={t('createEvent.mapsLinkPlaceholder')}
                    onChange={inputChangeHandler}
                    className="full-width"
                />
                <CustomDatePicker
                    selected={formValues.date}
                    onChange={date => onDateChange(date!)}
                    minDate={new Date()}
                    label={t('createEvent.dateAndTime')}
                    dateFormat="dd/MM/yyyy HH:mm"
                    showTimeSelect
                    className="full-width"
                    error={errors.date}
                />
                <TextInput
                    value={formValues.duration}
                    name="duration"
                    label={t('createEvent.duration')}
                    onChange={inputChangeHandler}
                    className="full-width"
                    error={errors.duration}
                />
                <div className="double-input-wrapper">
                    <TextInput
                        value={formValues.totalPrice}
                        name="totalPrice"
                        label={t('createEvent.totalPrice')}
                        placeholder={t('createEvent.totalPricePlaceholder')}
                        onChange={inputChangeHandler}
                        error={errors.totalPrice}
                    />
                    <TextInput
                        value={auth.authData?.currency || ''}
                        name="currency"
                        label={t('createEvent.currency')}
                        isDisabled
                        onChange={() => null}
                    />
                </div>
                <TextInput
                    value={formValues.ownerNotes}
                    name="ownerNotes"
                    label={t('createEvent.additionalNotes')}
                    placeholder={t('createEvent.additionalNotesPlaceholder')}
                    onChange={inputChangeHandler}
                    className="full-width"
                    isTextArea
                />
                <div className="create-event-save-btn">
                    <Button
                        type="submit"
                        text={t('createEvent.createEvent')}
                        variant="green"
                        size="lg"
                        disabled={isLoading}
                    />
                </div>
            </form>
        </div>
    );
};

export default CreateEvent;
