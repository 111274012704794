import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEventAPI } from '../../api/events';

import { CreateEventPayload, OptionType } from '../../utils/types';

interface CreateEventFormValues {
    name: string;
    type: string;
    street: string;
    mapsUrl: string;
    ownerNotes: string;
    totalPrice: number;
    date: Date | null;
    duration: number;
    isPrivate: boolean;
    isPartial: boolean;
    maxUsers: number;
}

const defaultFormValues = {
    name: '',
    type: '',
    street: '',
    mapsUrl: '',
    totalPrice: 0,
    date: null,
    duration: 0,
    isPrivate: false,
    isPartial: false,
    maxUsers: 0,
    ownerNotes: '',
};

interface Errors {
    name: string | null;
    type: string | null;
    street: string | null;
    totalPrice: string | null;
    date: string | null;
    duration: string | null;
    isPrivate: string | null;
    isPartial: string | null;
    maxUsers: string | null;
}

export const useCreateEvent = () => {
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState<CreateEventFormValues>({
        ...defaultFormValues,
    });
    const [errors, setErrors] = useState<Errors>({
        name: null,
        type: null,
        street: null,
        totalPrice: null,
        date: null,
        duration: null,
        isPrivate: null,
        maxUsers: null,
        isPartial: null,
    });
    const [serverError, setServerError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setErrors({
            name: null,
            type: null,
            street: null,
            totalPrice: null,
            date: null,
            duration: null,
            isPrivate: null,
            maxUsers: null,
            isPartial: null,
        });
    }, [formValues]);

    const inputChangeHandler = (
        name: string,
        value: string | boolean | number
    ) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const onRadioChange = (selectedOption: string) => {
        setFormValues({
            ...formValues,
            isPrivate: selectedOption === 'private',
            isPartial: selectedOption === 'partial',
        });
    };

    const onEventTypeChange = (name: string, result: OptionType) => {
        setFormValues({
            ...formValues,
            [name]: result.id,
        });
    };

    const onDateChange = (newDate: Date) => {
        setFormValues({
            ...formValues,
            date: newDate,
        });
    };

    const onSave = async (event: React.FormEvent) => {
        event.preventDefault();

        let hasErrors = false;
        const formErrors = { ...errors };
        let key: keyof CreateEventFormValues;
        for (key in formValues) {
            if (
                (formValues[key] === null || formValues[key] === '') &&
                key !== 'mapsUrl' &&
                key !== 'ownerNotes'
            ) {
                hasErrors = true;
                formErrors[key] = 'This field is required';
            }

            if (
                (key === 'maxUsers' || key === 'duration') &&
                formValues[key] === 0
            ) {
                hasErrors = true;
                formErrors[key] = 'This field is required';
            }

            if (
                key === 'maxUsers' &&
                !formValues.isPartial &&
                Number(formValues[key]) % 2 !== 0
            ) {
                hasErrors = true;
                formErrors[key] = `Must be an even number`;
            }

            if (
                (key === 'duration' || key === 'totalPrice') &&
                Number(formValues[key]) % 2 !== 0
            ) {
                hasErrors = true;
                formErrors[key] = `Must be a number`;
            }
        }

        if (hasErrors) {
            setErrors({
                ...formErrors,
            });
            return;
        }
        try {
            setIsLoading(true);

            const createEventPayload: CreateEventPayload = {
                ...formValues,
                maxUsers: Number(formValues.maxUsers),
                totalPrice: Number(formValues.totalPrice),
                duration: Number(formValues.duration),
                date: formValues.date?.toISOString()!,
            };
            const response = await createEventAPI(createEventPayload);

            navigate(`/events/${response._id}`);
        } catch (e) {
            setServerError(true);
        }
    };

    return {
        formValues,
        errors,
        inputChangeHandler,
        onEventTypeChange,
        onDateChange,
        onSave,
        onRadioChange,
        serverError,
        isLoading,
    };
};
