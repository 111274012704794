import { useMemo } from 'react';
import DatePicker from 'react-datepicker';

import './customDatePicker.css';
import Title from '../Title/Title';

interface CustomDatePickerProps {
    selected: Date | null;
    onChange: (date: Date) => void;
    minDate: Date;
    label?: string;
    dateFormat: string;
    showTimeSelect?: boolean;
    className?: string;
    error?: string | null;
    disabled?: boolean;
}

const CustomDatePicker = ({
    selected,
    onChange,
    minDate,
    label,
    dateFormat,
    showTimeSelect,
    className,
    error,
    disabled = false,
}: CustomDatePickerProps) => {
    const filterPassedTime = useMemo(
        () => (time: Date) => {
            const currentDate = new Date();
            const selectedDate = new Date(time);

            return currentDate.getTime() < selectedDate.getTime();
        },
        []
    );

    const onDateChangeHandler = (newDate: Date) => {
        onChange(newDate);
    };

    return (
        <div className={`custom-date-picker-wrapper ${className}`}>
            {label && (
                <label>
                    <Title text={label} size="s" />
                </label>
            )}
            {showTimeSelect ? (
                <DatePicker
                    selected={selected}
                    onChange={date => onChange(date!)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat={dateFormat}
                    filterTime={filterPassedTime}
                    className={error ? 'error' : ''}
                    minDate={minDate}
                    disabled={disabled}
                    onKeyDown={e => {
                        e.preventDefault();
                    }}
                />
            ) : (
                <DatePicker
                    selected={selected}
                    onChange={onDateChangeHandler}
                    minDate={minDate}
                    dateFormat={dateFormat}
                    disabled={disabled}
                    onKeyDown={e => {
                        e.preventDefault();
                    }}
                />
            )}
            {error && <span className="error-message">{error}</span>}
        </div>
    );
};

export default CustomDatePicker;
