import { useNavigate } from 'react-router-dom';
import { IEvent, ITeam } from '../../utils/types';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import { Icon, TextField, Title } from '../common';
import { useAppSelector } from '../../store/hooks';
import { COLOURS } from '../../utils/consts';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { renderUserName } from '../../utils/helpers';

import './eventDetails.css';

interface EventDetailsTeamsProps {
    eventDetails: IEvent;
    onJoinEvent: (teamId: string) => void;
    onChangeTeam: (teamId: string) => void;
    showJoinEventButton: boolean;
    isLoading: boolean;
}

const EventDetailsTeams = ({
    eventDetails,
    onJoinEvent,
    onChangeTeam,
    showJoinEventButton,
    isLoading,
}: EventDetailsTeamsProps) => {
    const navigate = useNavigate();
    const auth = useAppSelector(state => state.auth);
    const t = useTranslations();

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    const renderEmptySlots = (team: ITeam) => {
        const otherTeam = eventDetails.teams.find(
            otherTeam => otherTeam._id !== team._id
        );

        const isEventerInCurrentTeam = !!team?.users.find(
            user => user._id === auth.authData?._id
        );

        const isEventerInOtherTeam = !!otherTeam?.users.find(
            user => user._id === auth.authData?._id
        );

        const maxCounter = eventDetails.maxUsers / 2 - team.users.length;

        const jsx = [];
        let joinAdded = false;
        let changeTeamAdded = false;

        for (let i = 0; i < maxCounter; i++) {
            if (
                !isEventerInCurrentTeam &&
                !isEventerInOtherTeam &&
                !joinAdded &&
                !eventDetails.isCancelled &&
                showJoinEventButton
            ) {
                joinAdded = true;
                jsx.push(
                    <div
                        className="event-details-teams-empty"
                        key={`${team._id}_${i}`}
                    >
                        <div className="action">
                            <Icon
                                name="bx-plus"
                                onClick={() => onJoinEvent(team._id)}
                                color={COLOURS.GREEN}
                                size="30px"
                                cursor="pointer"
                            />
                            <Title text={t('common.join')} size="s" />
                        </div>
                    </div>
                );
            } else if (
                !isEventerInCurrentTeam &&
                isEventerInOtherTeam &&
                !eventDetails.isPartial &&
                !changeTeamAdded &&
                !eventDetails.isCancelled
            ) {
                changeTeamAdded = true;
                jsx.push(
                    <div
                        className="event-details-teams-empty"
                        key={`${team._id}_${i}`}
                    >
                        <div className="action">
                            <Icon
                                name="bx-transfer"
                                onClick={() => onChangeTeam(team._id)}
                                color={COLOURS.GREEN}
                                size="30px"
                                cursor="pointer"
                            />
                            <Title text={t('common.changeTeam')} size="s" />
                        </div>
                    </div>
                );
            } else {
                jsx.push(
                    <div
                        className="event-details-teams-empty"
                        key={`${team._id}_${i}`}
                    >
                        <div>
                            <TextField>{t('common.empty')}</TextField>
                        </div>
                    </div>
                );
            }
        }
        return jsx;
    };

    return (
        <div className="event-details-teams">
            {eventDetails.teams.map((team, index) => (
                <div key={team._id} className="event-details-team">
                    {!eventDetails.isPartial && (
                        <Title
                            text={`${t('common.team')} ${
                                index === 0 ? 'A' : 'B'
                            }`}
                            size="l"
                        />
                    )}
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {team.users.map(user => (
                                <div
                                    key={user._id}
                                    className="event-details-user"
                                >
                                    <UserAvatar
                                        imageSrc={user.avatarUrl}
                                        size="l"
                                        onClick={() =>
                                            userClickHandler(user._id)
                                        }
                                    />
                                    <TextField>
                                        {renderUserName(
                                            user.name,
                                            user.surname
                                        )}
                                    </TextField>
                                </div>
                            ))}
                            {renderEmptySlots(team)}
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};

export default EventDetailsTeams;
