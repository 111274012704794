import { isAfter, parseISO } from 'date-fns';
import EventDetailsInfo from '../EventDetailsInfo/EventDetailsInfo';
import { TextField, Title, UserAvatar } from '../../common';
import ClosedEventTeams from './ClosedEventTeams';
import { IEvent } from '../../../utils/types';
import { eventTypeMap } from '../../../utils/consts';

import './closedEvent.css';
import { renderVoteEndsDate } from '../../../utils/helpers';

interface ClosedEventProps {
    eventDetails: IEvent;
}

const ClosedEvent = ({ eventDetails }: ClosedEventProps) => {
    const showVoteEndsDate =
        eventDetails.isVotingPhase &&
        isAfter(parseISO(eventDetails.votingPhaseEndDate!), new Date());

    return (
        <div className="closed-event">
            <div className="event-details-header">
                <UserAvatar size="xl" imageSrc={eventDetails.owner.avatarUrl} />
                <div className="event-details-header-info">
                    <Title
                        text={`${eventTypeMap[eventDetails.type]} - ${
                            eventDetails.name
                        }`}
                        size="xl"
                    />
                    {showVoteEndsDate && (
                        <TextField
                            size="m"
                            color="green"
                        >{`Vote ends: ${renderVoteEndsDate(
                            eventDetails.votingPhaseEndDate!
                        )}`}</TextField>
                    )}
                </div>
            </div>
            <div className="event-details-main">
                <div className="event-details-section">
                    <EventDetailsInfo eventDetails={eventDetails} />
                    <ClosedEventTeams eventDetails={eventDetails} />
                </div>
            </div>
        </div>
    );
};

export default ClosedEvent;
