import { useEffect, useState } from 'react';
import { Button, Title, TextField, Icon } from '../../common';
import { Auth } from '../../../utils/types';
import { renderUserName } from '../../../utils/helpers';
import { COLOURS } from '../../../utils/consts';

interface DialogProps {
    isOpen: boolean;
    isReportLoading: boolean;
    user: Auth | null;
    bodyText: string;
    onClose: () => void;
    onReportUser: (userId: string) => void;
}

const ReportUserDialog: React.FC<DialogProps> = ({
    isOpen,
    user,
    isReportLoading,
    onClose,
    onReportUser,
}) => {
    useEffect(() => {
        setShowSuccessScreen(false);
    }, [user]);
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    if (!isOpen) {
        return null;
    }

    const userName = renderUserName(user?.name, user?.surname);

    const bodyText = showSuccessScreen
        ? `A report for the user ${userName} has been generated correctly`
        : `Are you sure you want to report ${userName} for not showing up to the event?`;

    const titleText =
        showSuccessScreen && !isReportLoading
            ? `Thank you for reporting`
            : `Report user ${userName}`;

    const reportUserHandler = () => {
        onReportUser(user?._id as string);
        setShowSuccessScreen(true);
    };

    return (
        <div className="dialog-overlay">
            <div className="dialog-container">
                <div className="dialog-header red">
                    <Icon
                        name="bx-x"
                        color={COLOURS.GREY}
                        size="30px"
                        cursor="pointer"
                        onClick={onClose}
                    />
                    <Title text={titleText}></Title>
                </div>
                <div className="dialog-body">
                    {isReportLoading ? (
                        <TextField>Loading...</TextField>
                    ) : (
                        <TextField>{bodyText}</TextField>
                    )}
                </div>
                <div className="dialog-footer">
                    {showSuccessScreen && !isReportLoading ? (
                        <Button
                            type="button"
                            text="Close"
                            onClick={onClose}
                            size="lg"
                        />
                    ) : (
                        <>
                            <Button
                                type="button"
                                text="Cancel"
                                onClick={onClose}
                                disabled={isReportLoading}
                                size="lg"
                            />
                            <Button
                                type="button"
                                text="Confirm"
                                variant="red"
                                onClick={reportUserHandler}
                                disabled={isReportLoading}
                                size="lg"
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportUserDialog;
