import { useEffect, useMemo, useState } from 'react';
import { fetchUserAPI } from '../../api/users';
import { User, UnpopulatedEvent } from '../../utils/types';

interface EventerDetailsResponse {
    data: User;
    eventHistory: UnpopulatedEvent[];
    nextEvents: UnpopulatedEvent[];
}

export const useUserPage = (userId: string | undefined) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isMoreLoading, setIsMoreLoading] = useState(false);
    const [userResponse, setEventerResponse] =
        useState<EventerDetailsResponse | null>(null);
    const [error, setError] = useState(null);
    const [limit, setLimit] = useState(10);
    const [eventCount, setEventCount] = useState(0);

    useEffect(() => {
        async function fetchData() {
            if (userId) {
                try {
                    if (limit > 10) {
                        setIsMoreLoading(true);
                    } else {
                        setIsLoading(true);
                    }
                    const data = await fetchUserAPI(userId, limit);
                    setEventerResponse(data);
                    setEventCount(data.historyCount);
                } catch (error: any) {
                    console.warn(error);
                    setError(error);
                }
                setIsLoading(false);
                setIsMoreLoading(false);
            }
        }
        fetchData();
    }, [userId, limit]);

    const showLoadMoreButton = useMemo(
        () =>
            userResponse?.eventHistory?.length
                ? userResponse?.eventHistory?.length < eventCount
                : false,
        [userResponse, eventCount]
    );

    const onShowMoreClick = async () => {
        const newLimit = limit + 10;
        setLimit(newLimit);
    };

    return {
        isLoading,
        userResponse,
        error,
        showLoadMoreButton,
        onShowMoreClick,
        isMoreLoading,
    };
};
