import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import mockRequest from '../../utils/mockRequest';
import { GeoDataState, City } from '../../utils/types';
import { RootState } from '../store';

const initialState: GeoDataState = {
    isLoading: false,
    error: null,
    cities: null,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        refreshCities(state, action) {
            state.cities = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(getCountryCities.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getCountryCities.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
            state.cities = null;
        });
        builder.addCase(
            getCountryCities.fulfilled,
            (state, action: PayloadAction<City[]>) => {
                state.cities = action.payload;
                state.isLoading = false;
            }
        );
    },
});

export const { refreshCities } = authSlice.actions;
export const selectGeoData = (state: RootState) => state.geoData;

export const getCountryCities = createAsyncThunk(
    'cities',
    async (countryCode: string) => {
        const response = await mockRequest.get(
            `/geo-data/cities?countryCode=${countryCode}`
        );
        if (!response.data) {
            throw new Error('response was not ok');
        }
        return response.data;
    }
);

export default authSlice.reducer;
