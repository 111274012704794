import Title from '../Title/Title';
import './section.css';

interface SectionProps {
    title: string;
    isSuccess?: boolean;
    isError?: boolean;
    children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
    title,
    isSuccess,
    isError,
    children,
}) => {
    const titleColor = isSuccess ? 'green' : isError ? 'red' : 'black';
    return (
        <div className="section-wrapper">
            <Title text={title} size="xl" color={titleColor} />
            {children}
        </div>
    );
};

export default Section;
