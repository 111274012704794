import { format } from 'date-fns';
import { IEvent } from '../../../utils/types';
import { Title, TextField } from '../../common';
import { useTranslations } from '../../../hooks/translations/useTranslations';

import './eventDetailsInfo.css';

interface EventDetailsInfoProps {
    eventDetails: IEvent;
}

const EventDetailsInfo = ({ eventDetails }: EventDetailsInfoProps) => {
    const t = useTranslations();

    const eventPrice =
        (eventDetails.totalPrice / eventDetails.maxUsers) % 1 !== 0
            ? (eventDetails.totalPrice / eventDetails.maxUsers).toFixed(1)
            : eventDetails.totalPrice / eventDetails.maxUsers;

    const renderEventPrice =
        eventPrice === 0
            ? t('common.free')
            : `${eventPrice} ${eventDetails.currency} ${t('common.perPerson')}`;

    const eventDate = format(
        new Date(eventDetails.date),
        "eeee, dd MMM yyyy 'at' HH:mm"
    );

    return (
        <div className="event-details-info">
            <div>
                <Title text={t('common.dateAndTime')} size="l" />
                <TextField>{eventDate}</TextField>
            </div>
            <div>
                <Title text={t('common.duration')} size="l" />
                <TextField>{`${eventDetails.duration} ${t(
                    'common.minutes'
                )}`}</TextField>
            </div>
            <div>
                <Title text={t('common.totalPlayers')} size="l" />
                <TextField>{eventDetails.maxUsers}</TextField>
            </div>
            <div>
                <Title text={t('common.location')} size="l" />
                <TextField>{eventDetails.street}</TextField>
                {eventDetails.mapsUrl && (
                    <TextField>{eventDetails.mapsUrl}</TextField>
                )}
            </div>
            <div>
                <Title text={t('common.price')} size="l" />
                <TextField>{renderEventPrice}</TextField>
            </div>
            <div>
                <Title
                    text={
                        eventDetails.isPrivate
                            ? t('common.confirmPlayers')
                            : t('common.openEvent')
                    }
                    size="l"
                />
            </div>
            {eventDetails.ownerNotes && (
                <div>
                    <Title text={t('common.notes')} size="l" />
                    <TextField>{eventDetails.ownerNotes}</TextField>
                </div>
            )}
        </div>
    );
};

export default EventDetailsInfo;
