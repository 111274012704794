import Title from '../Title/Title';
import './tabs.css';

interface TabsProps {
    sections: string[];
    selectedTab: string;
    onChangeTab: (selectedTab: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ sections, selectedTab, onChangeTab }) => {
    return (
        <div className="tabs">
            {sections.map(section => (
                <div
                    key={section}
                    className="tab-section"
                    onClick={() => onChangeTab(section)}
                >
                    <Title
                        color={selectedTab === section ? 'green' : 'black'}
                        text={section}
                    />
                </div>
            ))}
        </div>
    );
};

export default Tabs;
