import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    acceptJoinRequestAPI,
    fetchEventDetails,
    joinEventAPI,
    leaveEventAPI,
    rejectJoinRequestAPI,
    requestJoinAPI,
    cancelEventAPI,
    changeTeamAPI,
    startVotingPhaseAPI,
    voteEventAPI,
} from '../../api/events';
import { useAppSelector } from '../../store/hooks';
import { IEvent } from '../../utils/types';

export const useEventDetails = (eventId: string | undefined) => {
    const auth = useAppSelector(state => state.auth);
    const [isEventLoading, setEventIsLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState<IEvent | null>(null);
    const [error, setError] = useState(null);

    const fetchEventData = useCallback(async () => {
        if (eventId) {
            try {
                setEventIsLoading(true);
                const data = await fetchEventDetails(eventId);
                setEventDetails(data);
            } catch (error: any) {
                console.warn(error);
                setError(error);
            }
            setEventIsLoading(false);
        }
    }, [eventId]);

    useEffect(() => {
        fetchEventData();
    }, [fetchEventData]);

    const joinEvent = async (teamId: string) => {
        try {
            if (eventId && teamId) {
                setIsLoading(true);
                const data = await joinEventAPI(eventId, teamId);
                setEventDetails(data);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const changeTeam = async (teamId: string) => {
        try {
            if (eventId && teamId) {
                setIsLoading(true);
                const data = await changeTeamAPI(eventId, teamId);
                setEventDetails(data);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const cancelEvent = useCallback(async () => {
        try {
            setIsLoading(true);
            const data = await cancelEventAPI(eventId as string);
            setEventDetails(data);
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    }, [eventId]);

    const leaveEvent = async (teamId?: string) => {
        try {
            if (eventId && teamId) {
                setIsLoading(true);
                const data = await leaveEventAPI(eventId, teamId);
                setEventDetails(data);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const hasUserJoined = useMemo(() => {
        const userTeam = eventDetails?.teams.find(team =>
            team.users.some(user => user._id === auth.authData?._id)
        );
        if (userTeam) return true;

        return false;
    }, [eventDetails, auth]);

    const requestToJoin = async () => {
        try {
            if (eventId) {
                setIsLoading(true);
                const data = await requestJoinAPI(eventId);
                setEventDetails(data);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const acceptJoinRequest = async (joinRequestId: string) => {
        try {
            if (eventId) {
                setIsLoading(true);
                const data = await acceptJoinRequestAPI(eventId, joinRequestId);
                setEventDetails(data);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const rejectJoinRequest = async (joinRequestId: string) => {
        try {
            if (eventId) {
                setIsLoading(true);
                const data = await rejectJoinRequestAPI(eventId, joinRequestId);
                setEventDetails(data);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const onNewBoardMessage = (updatedEvent: IEvent) => {
        setEventDetails(updatedEvent);
    };

    const onStartVotingPhase = async () => {
        setIsLoading(true);
        try {
            if (eventId) {
                const event = await startVotingPhaseAPI(eventId);

                setEventDetails(event);
            }
        } catch (error: any) {
            console.warn(error);
            setError(error);
        }
        setIsLoading(false);
    };

    const voteEvent = useCallback(
        async (likes: string[], winner: string | null) => {
            if (eventId) {
                try {
                    setIsLoading(true);
                    const data = await voteEventAPI(eventId, likes, winner);
                    setEventDetails(data);
                } catch (error: any) {
                    console.warn(error);
                    setError(error);
                }
                setIsLoading(false);
            }
        },
        [eventId]
    );

    return {
        isLoading,
        isEventLoading,
        eventDetails,
        error,
        joinEvent,
        leaveEvent,
        hasUserJoined,
        requestToJoin,
        acceptJoinRequest,
        rejectJoinRequest,
        cancelEvent,
        onStartVotingPhase,
        onNewBoardMessage,
        changeTeam,
        voteEvent,
    };
};
