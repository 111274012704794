import './textField.css';

interface TextProps {
    children: React.ReactNode;
    size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
    color?: 'green' | 'red' | 'black' | 'white';
    weight?: 'regular' | 'bold';
}

const TextField: React.FC<TextProps> = ({
    children,
    size = 'm',
    color = 'black',
    weight = 'regular',
}) => {
    return (
        <p className={`text-field ${color} ${size} ${weight}`}>{children}</p>
    );
};

export default TextField;
