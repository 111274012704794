import { useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { IBoardMessage, IEvent } from '../../../utils/types';
import { Icon, TextField, TextInput, UserAvatar } from '../../common';
import { writeBoardMessageAPI } from '../../../api/events';
import { useNavigate, useParams } from 'react-router-dom';
import { COLOURS } from '../../../utils/consts';
import { useTranslations } from '../../../hooks/translations/useTranslations';

import './eventBoard.css';
import { renderUserName } from '../../../utils/helpers';

interface EventBoardProps {
    board: IBoardMessage[];
    onNewBoardMessage: (updatedEvent: IEvent) => void;
    isEventCancelled?: boolean;
}

const EventBoard: React.FC<EventBoardProps> = ({
    board,
    onNewBoardMessage,
    isEventCancelled = false,
}) => {
    const { eventId } = useParams();
    const navigate = useNavigate();
    const t = useTranslations();

    const [newMessage, setNewMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const onMessageType = (_: string, newValue: string) => {
        setError(null);
        setNewMessage(newValue);
    };

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    const onEnterPress = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (isEventCancelled) {
            return;
        }

        if (isLoading) {
            return;
        }

        if (!newMessage) {
            setError(t('errors.emptyMessage'));
            return;
        }

        if (event.key !== 'Enter') {
            return;
        }
        try {
            setIsLoading(true);
            const data = await writeBoardMessageAPI(eventId!!, newMessage);
            onNewBoardMessage(data);
            setNewMessage('');

            setIsLoading(false);
        } catch (error: any) {
            console.warn(error);
            setError(error.response.data.error);
            setIsLoading(false);
        }
    };

    const onWriteMessage = async () => {
        if (isEventCancelled) {
            return;
        }

        if (isLoading) {
            return;
        }

        if (!newMessage) {
            setError('Cannot send an empty message');
            return;
        }
        try {
            setIsLoading(true);
            const data = await writeBoardMessageAPI(eventId!!, newMessage);
            onNewBoardMessage(data);
            setNewMessage('');

            setIsLoading(false);
        } catch (error: any) {
            console.warn(error);
            setError(error.response.data.error);
            setIsLoading(false);
        }
    };

    return (
        <div className="event-board">
            <div className="event-board-messages">
                {board.length > 0 ? (
                    board.map(message => (
                        <div
                            key={message._id}
                            className="event-board-message-row"
                        >
                            <UserAvatar
                                imageSrc={message.user.avatarUrl}
                                size="m"
                                onClick={() =>
                                    userClickHandler(message.user._id)
                                }
                            />
                            <div className="event-board-message">
                                <div>
                                    <TextField weight="bold" size="m">
                                        {renderUserName(
                                            message.user.name,
                                            message.user.surname
                                        )}
                                    </TextField>
                                    <TextField size="s">
                                        {`${formatDistanceToNow(
                                            new Date(message.date)
                                        )} ago`}
                                    </TextField>
                                </div>
                                <TextField size="s">
                                    {message.message}
                                </TextField>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="event-board-message-row">
                        <TextField size="s">
                            {t('eventDetails.noMessages')}
                        </TextField>
                    </div>
                )}
                {isLoading && (
                    <div className="event-board-message">
                        <TextField>Loading...</TextField>{' '}
                    </div>
                )}
            </div>
            {!isEventCancelled && (
                <div className="event-board-input">
                    <TextInput
                        value={newMessage}
                        name="newMessage"
                        onChange={onMessageType}
                        placeholder="Write to the board..."
                        isDisabled={isLoading}
                        error={error}
                        onKeyDown={onEnterPress}
                    />
                    <Icon
                        name="bx-send"
                        size="25px"
                        color={isLoading ? '#c0c0c0' : COLOURS.GREEN}
                        cursor="pointer"
                        title={t('eventDetails.sendMessage')}
                        onClick={onWriteMessage}
                    />
                </div>
            )}
        </div>
    );
};

export default EventBoard;
