import { useEffect } from 'react';
import { getCurrentUser } from '../../store/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useAuth = () => {
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!auth.isLoading && !auth.wasUserFetched && !auth.error) {
            dispatch(getCurrentUser());
        }
    }, [dispatch, auth]);

    return auth;
};
