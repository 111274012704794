import { useAppSelector } from '../../store/hooks';
import EventRow from '../common/EventRow/EventRow';
import UserDetails from '../common/UserDetails/UserDetails';
import { useDashboard } from './useDashboard';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { Link } from 'react-router-dom';
import { Title, TextField, GenericError } from '../common';

import './dashboard.css';

const Dashboard = () => {
    const auth = useAppSelector(state => state.auth);
    const t = useTranslations();

    const { isLoading, dashboardResponse, error } = useDashboard();

    if (error) {
        return <GenericError />;
    }

    return (
        <div className="dashboard">
            <UserDetails user={auth.authData!} isDashboard />
            {isLoading || !dashboardResponse ? (
                <div className="dashboard-section">Loading...</div>
            ) : (
                <>
                    <div className="dashboard-section">
                        <div className="dashboard-header">
                            <Title text={t('dashboard.nextEvents')} size="xl" />
                        </div>
                        <div className="dashboard-events">
                            {dashboardResponse.nextEvents.length > 0 ? (
                                dashboardResponse.nextEvents.map(record => (
                                    <EventRow
                                        key={record._id}
                                        eventRecord={record}
                                        isListView
                                    />
                                ))
                            ) : (
                                <div className="dashboard-empty-list">
                                    <TextField>
                                        {`${t('dashboard.noEvents')}, `}
                                        <Link
                                            className="text-md bold"
                                            to="/find"
                                        >
                                            {t('dashboard.findNewEvent')}
                                        </Link>
                                    </TextField>
                                </div>
                            )}
                        </div>
                    </div>
                    {dashboardResponse.pastEvents.length > 0 && (
                        <div className="dashboard-section">
                            <div className="dashboard-header">
                                <Title
                                    text={t('dashboard.pastEvents')}
                                    size="xl"
                                />
                            </div>
                            <div className="dashboard-events">
                                {dashboardResponse.pastEvents.map(record => (
                                    <EventRow
                                        key={record._id}
                                        eventRecord={record}
                                        actionRequired
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                    {dashboardResponse.awaitingVoteEvents.length > 0 && (
                        <div className="dashboard-section">
                            <div className="dashboard-header">
                                <Title
                                    text={t('dashboard.timeToVote')}
                                    size="xl"
                                />
                            </div>
                            <div className="dashboard-events">
                                {dashboardResponse.awaitingVoteEvents.map(
                                    record => (
                                        <EventRow
                                            key={record._id}
                                            eventRecord={record}
                                            showVoteEndDate
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    )}
                    <div className="dashboard-section">
                        <div className="dashboard-header">
                            <Title text={t('dashboard.eventsNear')} size="xl" />
                        </div>
                        <div className="dashboard-events">
                            {dashboardResponse.suggestedEvents.length > 0 ? (
                                dashboardResponse.suggestedEvents.map(
                                    record => (
                                        <EventRow
                                            key={record._id}
                                            eventRecord={record}
                                            isListView
                                        />
                                    )
                                )
                            ) : (
                                <div className="dashboard-empty-list">
                                    <TextField>{`${t(
                                        'dashboard.noEventsNear'
                                    )} ${auth.authData?.city}`}</TextField>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Dashboard;
