import React, { useState, useRef, useEffect, useMemo } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import defaultAvatar from '../../../images/defaultavatar.png';
import { useAuth } from '../../../hooks/auth/useAuth';
import { NotificationStatus } from '../../../utils/types';
import { Title, TextField, UserAvatar, Icon } from '../../common';
import { readNotifications } from '../../../api/auth';
import { refreshCurrentUser } from '../../../store/auth/authSlice';

import './notifications.css';

const Notifications = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const dispatch = useAppDispatch();

    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        document.addEventListener('mousedown', closeNotificationsMenu);
        return () => {
            document.removeEventListener('mousedown', closeNotificationsMenu);
        };
    });

    const closeNotificationsMenu = (event: MouseEvent) => {
        //@ts-ignore
        if (ref.current !== event.target && event.target?.nodeName !== 'P') {
            setShowMenu(false);
        }
    };

    const showMenuHandler = async (event: React.MouseEvent<SVGSVGElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (
            auth.authData?.notifications.some(
                notification =>
                    notification.status === NotificationStatus.UNREAD
            )
        ) {
            const userData = await readNotifications();
            dispatch(refreshCurrentUser(userData));
        }

        setShowMenu(true);
    };

    const navigateToEvent = (eventId: string) => {
        navigate(`/events/${eventId}`);
        setShowMenu(false);
    };

    const showNotificationDot = auth.authData?.notifications.some(
        notification => notification.status === NotificationStatus.UNREAD
    );

    const sortedNotifications = useMemo(() => {
        if (auth.authData?.notifications) {
            const copy = [...auth.authData?.notifications];
            return copy.sort(
                (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
            );
        }
        return [];
    }, [auth.authData?.notifications]);

    return (
        <div className="notifications" ref={ref}>
            <Icon
                name="bx-bell"
                onClick={showMenuHandler}
                size="23px"
                cursor="pointer"
            />
            {showNotificationDot && <div className="new-notifications-dot" />}

            {showMenu && (
                <ul className="notifications-dropdown-menu">
                    <li className="notifications-title">
                        <Title text="Notifications" size="s" />
                    </li>
                    {sortedNotifications.length > 0 ? (
                        sortedNotifications.map(notification => (
                            <li
                                className="notifications-menu-element"
                                key={notification._id}
                                onClick={() =>
                                    navigateToEvent(notification.event)
                                }
                            >
                                <UserAvatar
                                    imageSrc={
                                        notification.sender.avatarUrl ||
                                        defaultAvatar
                                    }
                                    size="m"
                                />
                                <div>
                                    <TextField size="m">
                                        {notification.text}
                                    </TextField>
                                    <TextField size="xs">{`${formatDistanceToNow(
                                        new Date(notification.date)
                                    )} ago`}</TextField>
                                </div>
                            </li>
                        ))
                    ) : (
                        <li className="notifications-menu-element">
                            <TextField size="s">
                                There are no notifications
                            </TextField>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default Notifications;
