import { useEffect, useMemo, useRef, useState } from 'react';
import { OptionType, UpdateInfoPayload } from '../../utils/types';
import { useGeoData } from '../../hooks/geoData/useGeoData';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { saveUserInfo, refreshCurrentUser } from '../../store/auth/authSlice';
import { uploadImage } from '../../api/auth';
import { refreshCities } from '../../store/geoData/geoDataSlice';
import { LANGUAGES } from '../../utils/consts';

const initialFormValues = {
    country: null,
    city: null,
    language: null,
};

interface FormValues {
    country: string | null;
    city: string | null;
    language: LANGUAGES | null;
}

interface Errors {
    country: string | null;
    city: string | null;
    language: string | null;
}

export const useUserSettings = () => {
    const {
        isLoading: isGeoDataLoading,
        error: geoDataError,
        getAllCountries,
        getCities,
        getAllCountryCities,
        countriesData,
        citiesData,
    } = useGeoData();
    const geoData = useAppSelector(state => state.geoData);
    const auth = useAppSelector(state => state.auth);

    const {
        country,
        countryCode: userCountryCode,
        language,
        city,
    } = auth.authData!;

    const initialized = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [formValues, setFormValues] = useState<FormValues>({
        country: null,
        city: null,
        language: null,
    });
    const [errors, setErrors] = useState<Errors>(initialFormValues);
    const [countryCode, setCountryCode] = useState(userCountryCode);
    const [currency, setCurrency] = useState('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!initialized.current && !countriesData) {
            getAllCountries();
        }
        return () => {
            initialized.current = true;
        };
    }, [getAllCountries, countriesData]);

    const inputChangeHandler = (name: string, value: string) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setErrors({
            ...initialFormValues,
        });
    };

    const onLanguageChange = (fieldName: string, result: OptionType) => {
        setFormValues({
            ...formValues,
            [fieldName]: result.id,
        });
    };

    const onSubmitHandler = () => {
        const formErrors = { ...errors };

        if (formValues.country && !formValues.city) {
            setErrors({
                ...formErrors,
                city: 'This field is required',
            });
            return;
        }

        const payload: UpdateInfoPayload = {
            city: formValues.city || city,
            country: formValues.country || country,
            language: formValues.language || language,
        };
        dispatch(refreshCities(citiesData));
        dispatch(saveUserInfo(payload));
    };

    const generateOptions = useMemo(() => {
        const countryOptions: OptionType[] = [];
        const citiesOptions: OptionType[] = [];
        const cityList = formValues.country ? citiesData : geoData.cities;
        countriesData?.forEach(country => {
            countryOptions.push({
                id: country.code,
                label: country.name,
            });
        });
        cityList?.forEach(city => {
            citiesOptions.push({
                id: city.name,
                label: city.name,
            });
        });
        return { countryOptions, citiesOptions };
    }, [countriesData, citiesData, formValues.country, geoData.cities]);

    const onCountryChange = (fieldName: string, result: OptionType) => {
        getAllCountryCities(result.id as string);
        setCountryCode(result.id as string);
        const selectedCurrency = countriesData?.find(
            country => country.code === result.id
        )?.currency;

        setCurrency(selectedCurrency || '');
        setFormValues({
            ...formValues,
            city: null,
            [fieldName]: result.label,
        });

        setErrors({
            ...errors,
            country: null,
            city: null,
        });
    };

    const onCityChange = (fieldName: string, result: OptionType) => {
        setFormValues({
            ...formValues,
            [fieldName]: result.label,
        });

        setErrors({
            ...errors,
            country: null,
            city: null,
        });
    };

    const getCitiesHandler = (initials: string) => {
        getCities(countryCode, initials);
    };

    const uploadImageHandler = async (formData: FormData) => {
        try {
            setIsLoading(true);
            const response = await uploadImage(formData);

            dispatch(refreshCurrentUser(response));
            setIsLoading(false);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            setError(
                'Something went wrong while uploading the image, please try again later'
            );
        }
    };

    return {
        isGeoDataLoading,
        geoDataError,
        generateOptions,
        onCountryChange,
        getCitiesHandler,
        inputChangeHandler,
        onSubmitHandler,
        formValues,
        errors,
        countryCode,
        currency,
        onCityChange,
        isLoading,
        uploadImageHandler,
        error,
        onLanguageChange,
    };
};
