import { useMemo, useState } from 'react';
import {
    Button,
    GenericError,
    Icon,
    ImageUploader,
    TextField,
    TextInput,
    Title,
    UserAvatar,
} from '../common';
import { useUserSettings } from './useUserSettings';
import Select from '../common/Select/Select';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { COLOURS, languageOptions } from '../../utils/consts';
import { useAppSelector } from '../../store/hooks';

import './userSettings.css';

const UserSettings: React.FC = () => {
    const auth = useAppSelector(state => state.auth);
    const t = useTranslations();

    const currentUser = auth.authData!;

    const {
        isGeoDataLoading,
        geoDataError,
        generateOptions,
        onCountryChange,
        onCityChange,
        onSubmitHandler,
        formValues,
        errors,
        countryCode,
        currency,
        isLoading,
        error,
        uploadImageHandler,
        onLanguageChange,
    } = useUserSettings();

    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const isSubmitDisabled = useMemo(() => {
        if (formValues.country) {
            return formValues.city ? false : true;
        }

        return !formValues.country && !formValues.city && !formValues.language;
    }, [formValues]);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        onSubmitHandler();
    };

    // TODO: loader
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (geoDataError) {
        return <GenericError />;
    }

    return (
        <div className="user-settings">
            <div className="user-settings-header-wrapper">
                <div className="user-settings-header">
                    <UserAvatar
                        imageSrc={selectedImage || currentUser.avatarUrl}
                        size="xl"
                    />
                    <div>
                        <Title text={t('userSettings.title')} size="xl" />
                        <ImageUploader
                            onImageSelection={url => setSelectedImage(url)}
                            onUploadImage={uploadImageHandler}
                        />
                        {error && <TextField color="red">{error}</TextField>}
                    </div>
                </div>
                {(!auth.authData?.avatarUrl || selectedImage) && (
                    <div className="flex gap">
                        <Icon name="bx-error" color={COLOURS.RED} />
                        <TextField>{t('userSettings.proTip')}</TextField>
                    </div>
                )}
            </div>

            <div className="user-settings-content">
                <div className="user-settings-form">
                    <form onSubmit={onSubmit}>
                        <div className="user-settings-form-text">
                            <TextField>Username</TextField>
                            <TextField weight="bold">
                                {currentUser.username}
                            </TextField>
                        </div>
                        <Select
                            name="language"
                            options={languageOptions}
                            onSelect={onLanguageChange}
                            value={formValues.language || currentUser.language}
                            label={t('register.language')}
                            className="full-width"
                            error={errors.language}
                            isDisabled={auth.isLoading || isGeoDataLoading}
                        />
                        <Select
                            name="country"
                            options={generateOptions.countryOptions}
                            onSelect={onCountryChange}
                            value={countryCode as string}
                            label={t('common.country')}
                            className="full-width"
                            error={errors.country}
                            isDisabled={auth.isLoading || isGeoDataLoading}
                        />
                        <Select
                            name="city"
                            options={generateOptions.citiesOptions}
                            onSelect={onCityChange}
                            value={formValues.city || currentUser.city}
                            label={t('common.city')}
                            className="full-width"
                            isDisabled={auth.isLoading || isGeoDataLoading}
                        />
                        <TextInput
                            label={t('common.currency')}
                            name="currency"
                            placeholder={t('common.currency')}
                            isDisabled
                            value={currency || currentUser.currency}
                        />
                        <Button
                            type="submit"
                            text={t('userSettings.saveSettings')}
                            variant="green"
                            size="sm"
                            disabled={
                                isSubmitDisabled ||
                                auth.isLoading ||
                                isGeoDataLoading
                            }
                        />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UserSettings;
