import { Link } from 'react-router-dom';
import UserMenu from './UserMenu/UserMenu';
import { useAppSelector } from '../../store/hooks';
import './header.css';
import Title from '../common/Title/Title';
import SearchBar from './SearchBar/SearchBar';
import { TextField } from '../common';
import Notifications from './Notifications/Notifications';

const Header = () => {
    const auth = useAppSelector(state => state.auth);
    const isAuthenticated = !!auth.authData;

    return (
        <header>
            <div className="inner-header">
                <div className="left-header">
                    <Link
                        to={isAuthenticated ? '/dashboard' : '/'}
                        className="header-logo text-xl"
                    >
                        <Title text="Sportwice" color="green" size="l" />
                    </Link>
                </div>
                <div className="right-header">
                    {auth.isLoading ? null : (
                        <>
                            {isAuthenticated ? (
                                <>
                                    <Link to="/find">
                                        <TextField>Find</TextField>
                                    </Link>
                                    <Link to="/create">
                                        <TextField>Create</TextField>
                                    </Link>
                                    <div className="header-icons">
                                        <SearchBar />
                                    </div>
                                    <Notifications />
                                    <UserMenu authData={auth.authData} />
                                </>
                            ) : (
                                <Link className="login-btn" to="/login">
                                    <TextField>Login</TextField>
                                </Link>
                            )}
                        </>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
