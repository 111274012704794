import { useAppSelector } from '../../store/hooks';
import { format, isBefore, parseISO } from 'date-fns';
import { IEvent } from '../../utils/types';
import Button from '../common/Button/Button';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import { Title, TextField, Icon } from '../common';
import { COLOURS, eventTypeMap } from '../../utils/consts';
import { useTranslations } from '../../hooks/translations/useTranslations';

interface EventHeaderProps {
    eventDetails: IEvent;
    hasUserJoined: boolean;
    onShowCancelDialog: () => void;
    onLeaveEvent: () => void;
    onRequestToJoin: () => void;
    onGoToEditPage: () => void;
}

const EventHeader: React.FC<EventHeaderProps> = ({
    eventDetails,
    hasUserJoined,
    onShowCancelDialog,
    onLeaveEvent,
    onRequestToJoin,
    onGoToEditPage,
}) => {
    const t = useTranslations();

    const auth = useAppSelector(state => state.auth);

    const isOwner = eventDetails.owner._id === auth.authData?._id;
    const voteEndDate = eventDetails.votingPhaseEndDate
        ? format(new Date(eventDetails.votingPhaseEndDate), 'dd/MM/yyyy, HH:mm')
        : null;

    const showJoinRequestButton =
        !hasUserJoined &&
        !isOwner &&
        eventDetails.isPrivate &&
        !eventDetails.joinRequests.some(
            req => req.user._id === auth.authData?._id
        );

    if (eventDetails.isCancelled) {
        return (
            <div className="event-details-header">
                <UserAvatar size="xl" imageSrc={eventDetails.owner.avatarUrl} />
                <div className="event-details-header-info">
                    <Title
                        text={`${eventTypeMap[eventDetails.type]} - ${
                            eventDetails.name
                        }`}
                        size="l"
                    />
                    <div className="flex gap">
                        <Icon name="bx-error" color={COLOURS.RED} />
                        <TextField color="red">
                            {t('eventDetails.eventCancelled')}
                        </TextField>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="event-details-header">
            <UserAvatar size="xl" imageSrc={eventDetails.owner.avatarUrl} />
            <div className="event-details-subheader">
                <div className="event-details-header-info">
                    <Title
                        text={`${eventTypeMap[eventDetails.type]} - ${
                            eventDetails.name
                        }`}
                        size="l"
                    />
                    <TextField size="m">{`${eventDetails.owner.name} ${eventDetails.owner.surname}`}</TextField>
                    {eventDetails.isVotingPhase && (
                        <TextField
                            size="m"
                            color="green"
                        >{`Vote ends: ${voteEndDate}`}</TextField>
                    )}
                </div>
                {!eventDetails.isVotingPhase &&
                    isBefore(parseISO(eventDetails.date), new Date()) && (
                        <div className="event-details-actions">
                            {isOwner && (
                                <>
                                    <Icon
                                        name="bx-edit"
                                        size="28px"
                                        cursor="pointer"
                                        title="Edit event"
                                        onClick={onGoToEditPage}
                                    />
                                    <Icon
                                        name="bx-trash"
                                        size="28px"
                                        cursor="pointer"
                                        title="Cancel event"
                                        onClick={onShowCancelDialog}
                                    />
                                </>
                            )}
                            {hasUserJoined && (
                                <Icon
                                    name="bx-exit"
                                    size="28px"
                                    cursor="pointer"
                                    title="Leave event"
                                    onClick={onLeaveEvent}
                                />
                            )}
                            {showJoinRequestButton && (
                                <Button
                                    text={t('common.requestJoin')}
                                    onClick={onRequestToJoin}
                                    variant="success"
                                    size="sm"
                                />
                            )}
                        </div>
                    )}
            </div>
        </div>
    );
};

export default EventHeader;
