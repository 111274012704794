import { IEvent } from '../../utils/types';
import UserAvatar from '../common/UserAvatar/UserAvatar';
import { Icon, TextField, Title } from '../common';
import { useTranslations } from '../../hooks/translations/useTranslations';
import { useNavigate } from 'react-router-dom';
import { COLOURS } from '../../utils/consts';

interface EventJoinRequestsProps {
    eventDetails: IEvent;
    isOwner: boolean;
    acceptRequestHandler: (requestId: string) => void;
    rejectRequestHandler: (requestId: string) => void;
}

const EventJoinRequests = ({
    eventDetails,
    isOwner,
    acceptRequestHandler,
    rejectRequestHandler,
}: EventJoinRequestsProps) => {
    const navigate = useNavigate();
    const t = useTranslations();

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    return (
        <div className="event-invitations-wrapper">
            <Title text={t('common.joinRequests')} />
            <div>
                {eventDetails.joinRequests.map(request => (
                    <div key={request._id} className="event-invitation">
                        <div className="flex align-center">
                            <UserAvatar
                                size="m"
                                onClick={() =>
                                    userClickHandler(request.user._id)
                                }
                            />
                            <TextField>{request.user.username}</TextField>
                        </div>
                        {isOwner ? (
                            <>
                                <Icon
                                    name="bx-check"
                                    cursor="pointer"
                                    color={COLOURS.GREEN}
                                    onClick={() =>
                                        acceptRequestHandler(request._id)
                                    }
                                    title={t('eventDetails.acceptRequest')}
                                />
                                <Icon
                                    name="bx-x"
                                    cursor="pointer"
                                    color={COLOURS.RED}
                                    onClick={() =>
                                        rejectRequestHandler(request._id)
                                    }
                                    title={t('eventDetails.rejectRequest')}
                                />
                            </>
                        ) : (
                            <TextField weight="bold" size="s">
                                {t('common.pending')}
                            </TextField>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventJoinRequests;
