import { COLOURS } from '../../../utils/consts';
import { useTranslations } from '../../../hooks/translations/useTranslations';

import './dialog.css';
import { Button, Icon, TextField, Title } from '..';

interface DialogProps {
    isOpen: boolean;
    isWarning?: boolean;
    title: string;
    bodyText: string;
    onClose: () => void;
    onConfirm: () => void;
    isLoading?: boolean;
}

const Dialog: React.FC<DialogProps> = ({
    isOpen,
    isWarning = false,
    title,
    bodyText,
    onClose,
    onConfirm,
    isLoading = false,
}) => {
    const t = useTranslations();

    if (!isOpen) {
        return null;
    }

    return (
        <div className="dialog-overlay">
            <div className="dialog-container">
                <div className={`dialog-header ${isWarning ? 'red' : ''}`}>
                    <Icon
                        name="bx-x"
                        color={COLOURS.GREY}
                        size="30px"
                        cursor="pointer"
                        onClick={onClose}
                    />
                    <Title text={title}></Title>
                </div>
                <div className="dialog-body">
                    <TextField>{bodyText}</TextField>
                </div>
                <div className="dialog-footer">
                    <Button
                        type="button"
                        text={t('common.cancel')}
                        onClick={onClose}
                        size="lg"
                    />
                    <Button
                        type="button"
                        text={t('common.confirm')}
                        variant={isWarning ? 'red' : 'green'}
                        onClick={onConfirm}
                        size="lg"
                        disabled={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default Dialog;
