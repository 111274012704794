import { useCallback, useState } from 'react';
import { reportUserAPI } from '../../../api/events';
import { IEvent } from '../../../utils/types';

export const useVoteEvent = (eventId: string | undefined) => {
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updatedEvent, setUpdatedEvent] = useState<IEvent | null>(null);

    const reportUser = useCallback(
        async (userId: string) => {
            if (eventId) {
                try {
                    setIsReportLoading(true);
                    const response = await reportUserAPI(eventId, userId);

                    setUpdatedEvent(response);
                } catch (error: any) {
                    console.warn(error);
                    setError(error);
                }

                setIsReportLoading(false);
            }
        },
        [eventId]
    );

    return {
        error,
        reportUser,
        updatedEvent,
        isReportLoading,
    };
};
