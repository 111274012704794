import { useEffect } from 'react';
import { getCountryCities } from '../../store/geoData/geoDataSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useGeoData = () => {
    const geoData = useAppSelector(state => state.geoData);
    const auth = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (
            !geoData.isLoading &&
            !geoData.cities &&
            !geoData.error &&
            auth.authData
        ) {
            dispatch(getCountryCities(auth.authData?.countryCode));
        }
    }, [dispatch, geoData, auth]);

    return auth;
};
