import mockRequest from '../utils/mockRequest';

export const fetchUsersAPI = async (usersInitials: string) => {
    const results = await mockRequest.get(
        `/users/search?initials=${usersInitials}`
    );
    return results.data;
};

export const fetchUserAPI = async (userId: string, limit: number) => {
    const details = await mockRequest.get(`/users/${userId}?limit=${limit}`);
    return details.data;
};

export const fetchNextEvents = async () => {
    const nextEvents = await mockRequest.get(`/dashboard`);
    return nextEvents.data;
};
