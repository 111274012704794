import React, { ReactElement } from 'react';

import './textInput.css';
import Title from '../Title/Title';
import { Icon } from '..';

interface TextInputProps {
    isTextArea?: boolean;
    value: string | number | null;
    error?: string | null;
    name: string;
    placeholder?: string;
    label?: string;
    isDisabled?: boolean;
    type?: string;
    onChange?: (name: string, value: string) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    onCloseClick?: () => void;
    autoComplete?: string;
    icon?: ReactElement;
    className?: string;
    withCloseIcon?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
    isTextArea = false,
    value = null,
    error,
    name,
    placeholder,
    label,
    isDisabled,
    type = 'text',
    onChange = () => {},
    onKeyDown,
    onClick,
    autoComplete,
    icon,
    className,
    withCloseIcon = false,
    onCloseClick,
}) => {
    const validateOnChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        onChange(event.target.name, event.target.value);
    };

    return (
        <div className="input-container">
            {label && (
                <label htmlFor={name}>
                    <Title text={label} size="s" />
                </label>
            )}
            {!isTextArea ? (
                <>
                    {icon && icon}
                    <input
                        id={name}
                        name={name}
                        onChange={validateOnChange}
                        onKeyDown={onKeyDown}
                        placeholder={isDisabled ? '' : placeholder}
                        readOnly={isDisabled}
                        disabled={isDisabled}
                        type={type}
                        value={value || ''}
                        autoComplete={autoComplete}
                        className={`${className}
                            ${error ? 'error' : icon ? 'extra-padding' : ''}
                        `}
                        onClick={onClick}
                    />
                    {withCloseIcon && (
                        <Icon
                            name="bx-x"
                            cursor="pointer"
                            onClick={onCloseClick}
                            size="20px"
                        />
                    )}
                </>
            ) : (
                <textarea
                    id={name}
                    name={name}
                    onChange={validateOnChange}
                    placeholder={isDisabled ? '' : placeholder}
                    readOnly={isDisabled}
                    disabled={isDisabled}
                    value={value || ''}
                />
            )}
            {error && <span className="error-message">{error}</span>}
        </div>
    );
};

export default TextInput;
