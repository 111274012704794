import { useParams } from 'react-router-dom';
import { useUserPage } from './useUserPage';
import EventRow from '../common/EventRow/EventRow';
import UserDetails from '../common/UserDetails/UserDetails';
import { Button, GenericError, Title } from '../common';
import { useTranslations } from '../../hooks/translations/useTranslations';

import './userPage.css';

const UserPage = () => {
    const { userId } = useParams();
    const {
        isLoading,
        userResponse,
        error,
        showLoadMoreButton,
        onShowMoreClick,
        isMoreLoading,
    } = useUserPage(userId);
    const t = useTranslations();

    // TODO: loader
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <GenericError />;
    }

    if (!userResponse) {
        return null;
    }

    const { data, eventHistory, nextEvents } = userResponse;

    return (
        <div className="user-page">
            <UserDetails user={data} />
            <div className="user-history">
                <Title text={t('userDetails.nextEvents')} size="xl" />
                <div className="user-history-list">
                    {nextEvents.map(record => {
                        const userTeam = record.teams.find(team =>
                            team.users.includes(userId!)
                        );
                        const isWinner = record.winner === userTeam?.id;

                        return (
                            <EventRow
                                key={record._id}
                                eventRecord={record}
                                isWinner={isWinner}
                                isMvp={record.mvp === userId}
                                isUserPage
                                isListView
                            />
                        );
                    })}
                </div>
                <Title text={t('userDetails.lastEvents')} size="xl" />
                <div className="user-history-list">
                    {eventHistory.map(record => {
                        const userTeam = record.teams.find(team =>
                            team.users.includes(userId!)
                        );
                        const isWinner = record.winner === userTeam?.id;

                        return (
                            <EventRow
                                key={record._id}
                                eventRecord={record}
                                isWinner={isWinner}
                                isMvp={record.mvp === userId}
                                isUserPage
                            />
                        );
                    })}
                </div>
                {showLoadMoreButton && (
                    <div className="event-list-btn">
                        <Button
                            text="Show more records"
                            variant="green"
                            size="md"
                            onClick={onShowMoreClick}
                            disabled={isMoreLoading}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default UserPage;
