import { useNavigate } from 'react-router-dom';
import { IEvent } from '../../../utils/types';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import { Icon, TextField, Title } from '../../common';
import { renderUserName } from '../../../utils/helpers';
import { COLOURS } from '../../../utils/consts';

interface ClosedEventTeamsProps {
    eventDetails: IEvent;
}

const ClosedEventTeams = ({ eventDetails }: ClosedEventTeamsProps) => {
    const navigate = useNavigate();

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    const renderLikeIcon = (userId: string) => {
        const likeIndex = eventDetails.likes.findIndex(
            like => like.user === userId
        );

        const isMvp = eventDetails.mvp === userId;

        if (likeIndex !== -1) {
            return (
                <div className={`closed-event-stats ${isMvp ? 'mvp' : ''}`}>
                    {isMvp && <Icon name="bx-star" color="gold" />}
                    <div className="closed-event-like">
                        <Icon name="bxs-like" color={COLOURS.GREEN} />
                        <TextField>
                            {eventDetails.likes[likeIndex].likesCount}
                        </TextField>
                    </div>
                </div>
            );
        }

        return (
            <div className="closed-event-stats">
                <div className="closed-event-like">
                    <Icon name="bx-like" color="#dedede" />
                    <TextField>0</TextField>
                </div>
            </div>
        );
    };

    return (
        <div className="event-details-teams">
            {eventDetails.teams.map((team, index) => (
                <div key={team._id} className="vote-event-team">
                    {!eventDetails.isPartial && (
                        <div className="closed-event-team-name">
                            <Title text={`Team ${index === 0 ? 'A' : 'B'}`} />
                            {eventDetails.winner === team._id && (
                                <Icon
                                    name="bxs-trophy"
                                    size="50px"
                                    color="gold"
                                />
                            )}
                        </div>
                    )}
                    <div className="vote-event-team">
                        {team.users.map(user => (
                            <div
                                key={user._id}
                                className="vote-event-user-wrapper"
                            >
                                <div className="vote-event-user">
                                    <UserAvatar
                                        size="l"
                                        imageSrc={user.avatarUrl}
                                        onClick={() =>
                                            userClickHandler(user._id)
                                        }
                                    />
                                    <TextField>
                                        {renderUserName(
                                            user.name,
                                            user.surname
                                        )}
                                    </TextField>
                                </div>
                                {renderLikeIcon(user._id)}
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ClosedEventTeams;
