import { IconName } from 'boxicons';

import './icon.css';

interface IconProps {
    name: IconName;
    id?: string;
    size?: string;
    color?: string;
    cursor?: 'default' | 'pointer';
    title?: string;
    onClick?: (event: any) => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

export default function Icon({
    name,
    size = '24px',
    color = '',
    cursor = 'default',
    title,
    id,
    onClick,
    onMouseEnter,
    onMouseLeave,
}: IconProps) {
    return (
        <i
            className={`bx ${name}`}
            id={id}
            style={{ fontSize: size, color, cursor }}
            title={title}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        />
    );
}
