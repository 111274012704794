import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useFindEvents } from './useFindEvents';
import { OptionType } from '../../utils/types';
import Select from '../common/Select/Select';
import CustomDatePicker from '../common/CustomDatePicker/CustomDatePicker';
import EventRow from '../common/EventRow/EventRow';
import Section from '../common/Section/Section';
import {
    setCity,
    setEventType,
    setStartDate,
} from '../../store/eventSearchFilters/eventSearchFilters';
import { eventTypeOptions } from '../../utils/consts';
import { useTranslations } from '../../hooks/translations/useTranslations';

import './findEvents.css';
import { Button, GenericError, TextField } from '../common';

const FindEvents = () => {
    const auth = useAppSelector(state => state.auth);
    const geoData = useAppSelector(state => state.geoData);
    const t = useTranslations();

    const eventSearchFilters = useAppSelector(
        state => state.eventSearchFilters
    );
    const dispatch = useAppDispatch();

    const city = eventSearchFilters.city || auth.authData!.city;
    const eventType = eventSearchFilters.eventType || 'ALL';
    const startDate = eventSearchFilters.startDate;

    const {
        isLoading,
        events,
        error,
        showLoadMoreButton,
        onShowMoreClick,
        moreIsLoading,
    } = useFindEvents(city, eventType, startDate);

    const generateOptions = useMemo(() => {
        const eventTypes: OptionType[] = [
            { id: 'ALL', label: 'Show all' },
            ...eventTypeOptions,
        ];
        const citiesOptions: OptionType[] = [];

        geoData.cities?.forEach(city => {
            citiesOptions.push({
                id: city.name,
                label: city.name,
            });
        });
        return { eventTypes, citiesOptions };
    }, [geoData.cities]);

    const onCityChange = (_: string, result: OptionType) => {
        dispatch(setCity(result.label));
    };

    const onEventTypeChange = (_: string, result: OptionType) => {
        dispatch(setEventType(result.id as string));
    };

    const onStartDateChange = (newDate: Date) => {
        dispatch(setStartDate(newDate!));
    };

    if (error || geoData.error) {
        return <GenericError />;
    }

    return (
        <div className="find-events">
            <div className="find-event-inputs">
                <Select
                    name="city"
                    options={generateOptions.citiesOptions}
                    onSelect={onCityChange}
                    value={city as string}
                    label={t('findEvents.city')}
                    isDisabled={isLoading || geoData.isLoading}
                />
                <Select
                    name="eventType"
                    options={generateOptions.eventTypes}
                    onSelect={onEventTypeChange}
                    value={eventType}
                    label={t('findEvents.eventType')}
                    isDisabled={isLoading || geoData.isLoading}
                />
                <CustomDatePicker
                    selected={startDate || new Date()}
                    onChange={onStartDateChange}
                    minDate={new Date()}
                    label={t('findEvents.date')}
                    dateFormat="dd/MM/yyyy"
                    disabled={isLoading || geoData.isLoading}
                />
            </div>
            <div className="event-list">
                {!isLoading ? (
                    events?.length === 0 ? (
                        <Section title={t('findEvents.noEventsTitle')}>
                            <TextField>{t('findEvents.noEventsSub')}</TextField>
                        </Section>
                    ) : (
                        events?.map(eventRecord => (
                            <EventRow
                                key={eventRecord._id}
                                eventRecord={eventRecord}
                                isListView
                            />
                        ))
                    )
                ) : (
                    <div>Loading...</div>
                )}
                {moreIsLoading && <div>Loading...</div>}
                {showLoadMoreButton && (
                    <div className="event-list-btn">
                        <Button
                            text="Show more records"
                            variant="green"
                            size="md"
                            onClick={onShowMoreClick}
                            disabled={moreIsLoading}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FindEvents;
