import React from 'react';

import './button.css';
import Title from '../Title/Title';
import TextField from '../TextField/TextField';

interface ButtonProps {
    type?: 'button' | 'submit' | 'reset';
    text: string;
    variant?: 'green' | 'red' | 'transparent' | 'success' | 'warning' | 'retro';
    size?: 'sm' | 'md' | 'lg';
    disabled?: boolean;
    onClick?: (event: any) => void;
}

const Button: React.FC<ButtonProps> = ({
    text,
    type = 'button',
    variant,
    size,
    disabled = false,
    onClick,
}) => {
    return (
        <button
            className={`btn ${variant || ''} ${size || ''}`}
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            {variant === 'retro' ? (
                <TextField>{text}</TextField>
            ) : (
                <Title text={text} size="s" />
            )}
        </button>
    );
};

export default Button;
