import { format } from 'date-fns';

const emailCheck =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const capitalizeFirstLetter = (string: string): string =>
    string && string[0].toUpperCase() + string.slice(1);

export const isValidEmail = (email: string) =>
    emailCheck.test(email.toLocaleLowerCase());

export const isValidPassword = (pw: string) => {
    return (
        /[A-Z]/.test(pw) &&
        /[a-z]/.test(pw) &&
        /[0-9]/.test(pw) &&
        /[^-!@._*#%+]/.test(pw) &&
        /[^A-Za-z0-9]/.test(pw) &&
        pw.length > 4
    );
};

export const renderVoteEndsDate = (date: string) =>
    format(new Date(date), 'dd/MM/yyyy, HH:mm');

export function renderUserName(
    name: string | undefined,
    surname: string | undefined
) {
    if (!name || !surname) {
        return 'Invalid name or surname';
    }
    return `${name} ${surname.charAt(0)}.`;
}
