import { useEffect, useRef, useState } from 'react';
import { fetchNextEvents } from '../../api/users';
import { UnpopulatedEvent } from '../../utils/types';
import { useDispatch } from 'react-redux';
import { clearCurrentUser } from '../../store/auth/authSlice';

interface DashboardResponse {
    nextEvents: UnpopulatedEvent[];
    suggestedEvents: UnpopulatedEvent[];
    awaitingVoteEvents: UnpopulatedEvent[];
    pastEvents: UnpopulatedEvent[];
}

export const useDashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [dashboardResponse, setDashboardResponse] =
        useState<DashboardResponse | null>(null);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const initialized = useRef(false);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const data = await fetchNextEvents();
                setDashboardResponse(data);
            } catch (error: any) {
                if (error.response.status === 401) {
                    dispatch(clearCurrentUser());
                }
                console.warn(error);
                setError(error);
            }
            setIsLoading(false);
        }

        if (!initialized.current) {
            initialized.current = true;
            fetchData();
        }
    }, []);

    return { isLoading, dashboardResponse, error };
};
