import { useAppSelector } from '../../store/hooks';
import {
    Select,
    Button,
    TextInput,
    CustomDatePicker,
    RadioButton,
    GenericError,
} from '../common';
import { useEditEvent } from './useEditEvent';
import { eventTypeOptions } from '../../utils/consts';

import './editEvent.css';
import { useNavigate, useParams } from 'react-router-dom';

const EditEvent: React.FC = () => {
    const { eventId } = useParams();
    const navigate = useNavigate();

    const {
        formValues,
        formErrors,
        inputChangeHandler,
        onEventTypeChange,
        onDateChange,
        onRadioChange,
        onSave,
        isLoading,
        isSaveLoading,
        error,
    } = useEditEvent(eventId);
    const auth = useAppSelector(state => state.auth);

    const onGoBack = () => {
        navigate(-1);
    };

    const onSaveHandler = (event: React.FormEvent) => {
        event.preventDefault();

        onSave();
    };

    // TODO: loader
    if (isLoading && !error && !formValues.name) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <GenericError />;
    }

    return (
        <div className="edit-event">
            <div className="edit-event-form-wrapper">
                <form onSubmit={onSaveHandler} className="edit-event-form">
                    <RadioButton
                        label="Event type"
                        checked={formValues.isPrivate}
                        onChange={onRadioChange}
                        options={[
                            { name: 'public', tooltip: 'Everyone can join' },
                            {
                                name: 'private',
                                tooltip:
                                    'You have to accept new players before they can join',
                            },
                        ]}
                    />
                    <TextInput
                        name="name"
                        placeholder="Event name"
                        label="Name"
                        onChange={inputChangeHandler}
                        value={formValues.name}
                        error={formErrors.name}
                        className="full-width"
                    />
                    <Select
                        name="type"
                        options={eventTypeOptions}
                        onSelect={onEventTypeChange}
                        value={formValues.type as string}
                        label="Type"
                        className="full-width"
                        error={formErrors.type}
                    />
                    <TextInput
                        value={formValues.maxUsers}
                        name="maxUsers"
                        label="Total number of players"
                        onChange={inputChangeHandler}
                        className="full-width"
                        error={formErrors.maxUsers}
                    />
                    <TextInput
                        value={auth.authData?.city || ''}
                        name="city"
                        label="City"
                        isDisabled
                        onChange={() => null}
                        className="full-width"
                    />
                    <TextInput
                        value={formValues.street}
                        name="street"
                        label="Street"
                        placeholder="Street"
                        onChange={inputChangeHandler}
                        error={formErrors.street}
                        className="full-width"
                    />
                    <TextInput
                        value={formValues.mapsUrl}
                        name="mapsUrl"
                        label="Maps link (optional)"
                        placeholder="For example Google Maps link"
                        onChange={inputChangeHandler}
                        className="full-width"
                    />
                    <CustomDatePicker
                        selected={formValues.date}
                        onChange={date => onDateChange(date!)}
                        minDate={new Date()}
                        label="Date and time"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeSelect
                        className="full-width"
                        error={formErrors.date}
                    />
                    <TextInput
                        value={formValues.duration}
                        name="duration"
                        label="Duration (in minutes)"
                        onChange={inputChangeHandler}
                        className="full-width"
                        error={formErrors.duration}
                    />
                    <div className="double-input-wrapper">
                        <TextInput
                            value={formValues.totalPrice}
                            name="totalPrice"
                            label="Total price"
                            placeholder="Leave empty if free"
                            onChange={inputChangeHandler}
                            error={formErrors.totalPrice}
                        />
                        <TextInput
                            value={auth.authData?.currency || ''}
                            name="currency"
                            label="Currency"
                            isDisabled
                            onChange={() => null}
                        />
                    </div>
                    <TextInput
                        value={formValues.ownerNotes}
                        name="ownerNotes"
                        label="Additional notes"
                        placeholder="Add some tips to the players (e.g Team A white shirt, Team B black shirt)"
                        onChange={inputChangeHandler}
                        className="full-width"
                        isTextArea
                    />
                    <div className="edit-event-buttons">
                        <Button
                            type="button"
                            text="Cancel"
                            onClick={onGoBack}
                            size="lg"
                        />
                        <Button
                            type="submit"
                            text="Save"
                            variant="green"
                            size="lg"
                            disabled={isSaveLoading}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditEvent;
