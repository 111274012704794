import mockRequest from '../utils/mockRequest';
import { CompleteUserProfilePayload, RegisterPayload } from '../utils/types';

export async function login(email: string, password: string) {
    await mockRequest.post('/auth/login', { email, password });
}

export async function register(payload: RegisterPayload) {
    await mockRequest.post('/auth/register', payload);
}

export async function authenticate(email: string, emailToken: string) {
    const response = await mockRequest.post('/auth/authenticate', {
        email,
        emailToken,
    });

    return response.data;
}

export async function getResetPasswordToken(email: string) {
    await mockRequest.post('/auth/reset-password/code', { email });
}

export async function resetPassword(
    email: string,
    emailToken: string,
    password: string
) {
    await mockRequest.post('/auth/reset-password', {
        email,
        emailToken,
        password,
    });
}

export async function completeUserProfile(payload: CompleteUserProfilePayload) {
    await mockRequest.post('/auth/complete', payload);
}

export async function logout() {
    await mockRequest.get('/auth/logout');
}

export async function readNotifications() {
    const response = await mockRequest.get('/auth/notifications/read');

    return response.data;
}

export async function uploadImage(formData: FormData) {
    const response = await mockRequest.post('/auth/image/upload', formData);

    return response.data;
}
