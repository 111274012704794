import { useAppSelector } from '../../store/hooks';
import { LANGUAGES } from '../../utils/consts';

type ITranslations<T extends string> = {
    [key in T]: {
        [key in T]: {
            [key in T]: string;
        };
    };
};

const TRANSLATIONS: ITranslations<string> = {
    EN: {
        common: {
            emailSent: "We've sent a verification code to your email",
            enterCode: 'Enter code',
            next: 'Next',
            welcome: 'Welcome',
            actionRequired: 'Action required',
            voteEnds: 'Vote ends',
            edit: 'Edit',
            cancel: 'Cancel',
            leave: 'Leave',
            requestJoin: 'Request to join',
            free: 'Free',
            perPerson: 'per person',
            dateAndTime: 'Date and time',
            duration: 'Duration',
            minutes: 'minutes',
            totalPlayers: 'Total players',
            location: 'Location',
            price: 'Price',
            confirmPlayers: 'Owner must confirm players',
            openEvent: 'Everyone can join',
            notes: 'Additional info',
            join: 'Join here',
            changeTeam: 'Change team',
            empty: 'Empty',
            team: 'Team',
            joinRequests: 'Requests',
            pending: 'Pending',
            confirm: 'Confirm',
            country: 'Country',
            city: 'City',
            currency: 'Currency',
            contactUs: 'Contact us',
            eventExpired: 'Event expired',
            email: 'Email',
        },
        errors: {
            emptyMessage: 'Cannot send an empty message',
            genericError:
                'Whops, some error has occurred. Try refreshing the page or, if the issue persists, reach out to our support.',
            genericErrorTitle: 'Something went wrong...',
        },
        header: {
            find: 'Find',
            create: 'Create',
            searchPlaceHolder: 'Search users, events...',
            notifications: 'Notifications',
        },
        home: {
            title: 'Find sport events in your city',
            firstSubtitle:
                'Join Sportwice and find new people that shares the same passion as you.',
            secondSubtitle:
                'Find sport events in your city, meet new people and start playing now!',
            joinNow: 'Join now',
        },
        register: {
            title: 'Create an account',
            username: 'Username',
            language: 'Language',
            country: 'Country',
            city: 'City',
            signUp: 'Sign up',
            bottomText: 'Already have an account?',
            signIn: 'Sign in instead',
            accountCreated: 'Account created',
            name: 'Name',
            surname: 'Surname',
        },
        login: {
            title: 'Login to your account',
            enterEmail: 'Enter your email',
            noAccount: "Don't have an account?",
            createAccount: 'Create a new account',
            enterPassword: 'Enter your password',
            password: 'Password',
            login: 'Login',
        },
        resetPassword: {
            title: 'Reset your password',
            enterEmail: 'Enter your email',
            noAccount: "Don't have an account?",
            createAccount: 'Create a new account',
            enterPassword: 'Enter your password',
            confirmPassword: 'Confirm password',
            password: 'New password',
            login: 'Login',
            sendEmail: 'Send email',
            code: 'Security code (sent via email)',
            sendCode: 'Send code',
            save: 'Save',
        },
        contactUs: {
            title: 'Contact us',
            subtitle:
                "Have questions or want to suggest an improvement? We'd love to hear from you! Fill out the form and we'll be in touch as soon as possible.",
            email: 'Email address',
            emailTitle: 'Title',
            message: 'Message',
            sendMessage: 'Send message',
            thankYou: 'Thank you for the feedback',
            thankYouSub: "We'll be in touch as soon as possible.",
            backToHome: 'Back to home',
        },
        dashboard: {
            nextEvents: 'Next events',
            noEvents: 'You have no events scheduled for today',
            findNewEvent: 'find a new event now',
            pastEvents: 'Past events',
            timeToVote: 'Time to vote',
            eventsNear: 'Other events near you',
            noEventsNear: 'There are no other events today in',
        },
        userDetails: {
            reports: 'Number of reports for not showing up:',
            joined: 'Joined',
            created: 'Created',
            played: 'Played',
            likes: 'Likes',
            victories: 'Victories',
            defeats: 'Defeats',
            lastEvents: 'Last 30 days event history',
            nextEvents: 'Next events',
        },
        findEvents: {
            city: 'City',
            eventType: 'Event type',
            date: 'Date',
            noEventsTitle: 'Looks like there are no events today...',
            noEventsSub: 'Try changing the filters to search for more events',
        },
        createEvent: {
            partialEventTitle: 'How many people do you need?',
            fullEvent:
                'I want to find all the people for my event on the platform',
            partialEvent: 'I just need a few people to complete my event',
            publicEventTitle: 'How would the users join?',
            publicEvent: 'Everyone can join',
            privateEvent: 'You have to accept new players before they can join',
            name: 'Name',
            namePlaceholder: 'Event name',
            type: 'Type',
            totalNumber: 'Total number of players',
            city: 'City',
            street: 'Street address',
            mapsLink: 'Maps link (optional)',
            mapsLinkPlaceholder: 'For example Google Maps link',
            dateAndTime: 'Date and time',
            duration: 'Duration (in minutes)',
            totalPrice: 'Total price',
            totalPricePlaceholder: 'Leave empty if free',
            currency: 'Currency',
            additionalNotes: 'Additional notes',
            additionalNotesPlaceholder:
                'Add some tips to the players (e.g Team A white shirt, Team B black shirt)',
            createEvent: 'Create event',
        },
        eventDetails: {
            eventCancelled: 'This event has been cancelled',
            cancelDialogTitle: 'Event cancellation',
            cancelDialogBody: 'Are you sure you want to cancel this event?',
            leaveDialogTitle: 'Leaving the event',
            leaveDialogBody: 'Are you sure you want to leave this event?',
            firstTab: 'Details',
            secondTab: 'Board',
            noMessages: 'There are no messages',
            sendMessage: 'Send message',
            acceptRequest: 'Accept request',
            rejectRequest: 'Reject request',
        },
        userSettings: {
            title: 'My settings',
            changeImage: 'Change image',
            saveSettings: 'Save settings',
            saveImage: 'Save image',
            proTip: 'Pro tip: put a real picture of you so people can easily recognize you',
        },
    },
    PL: {
        common: {
            emailSent:
                'Przesłaliśmy kod weryfikacyjny na podany przez ciebie adres email',
            enterCode: 'Wprowadź kod weryfikacyjny',
            next: 'Dalej',
            welcome: 'Witaj',
            actionRequired: 'Wymagane działanie',
            voteEnds: 'Głosowanie kończy się',
            edit: 'Edytuj',
            cancel: 'Usuń',
            leave: 'Opuść mecz',
            requestJoin: 'Poproś o dołączenie',
            free: 'Za darmo',
            perPerson: 'za osobę',
            dateAndTime: 'Data i godzina',
            duration: 'Czas trwania',
            minutes: 'minuty',
            totalPlayers: 'Liczba graczy',
            location: 'Miejsce',
            price: 'Cena',
            confirmPlayers: 'Organizator musi zatwierdzić nowych uczestników',
            openEvent: 'Kazdy moze dołączyć',
            notes: 'Dodatkowe informacje',
            join: 'Dołącz',
            changeTeam: 'Zmień druzynę',
            empty: 'Wolne',
            team: 'Druzyna',
            joinRequests: 'Prośba o dołączenie',
            pending: 'Oczekująca',
            confirm: 'Potwierdź',
            country: 'Kraj',
            city: 'Miasto',
            currency: 'Waluta',
            contactUs: 'Skontaktuj się z nami',
            eventExpired: 'Wydarzenie wygasło',
        },
        errors: {
            emptyMessage: 'Tekst wymagany',
            genericError:
                'Ojej, pojawił się jakiś błąd. Odświez stronę, albo skontaktuj się z nami',
            genericErrorTitle: 'Coś poszło nie tak...',
        },
        header: {
            find: 'Szukaj',
            create: 'Utwórz',
            searchPlaceHolder: 'Szukaj graczy, meczy...',
            notifications: 'Notifikacje',
        },
        home: {
            title: 'Znajdź wydarzenia sportowe w Twoim mieście',
            firstSubtitle:
                'Dołącz do Sportwice i znajdź ludzi, którzy dzielą Twoje pasje.',
            secondSubtitle:
                'Znajdź wydarzenia sportowe w Twoim mieście, poznaj nowych ludzi i zacznij grać juz teraz!',
            joinNow: 'Dołącz teraz',
        },
        register: {
            title: 'Utwórz konto',
            username: 'Nazwa uzytkownika',
            language: 'Język',
            country: 'Kraj',
            city: 'Miasto',
            signUp: 'Zarejestruj się',
            bottomText: 'Masz juz konto?',
            signIn: 'Zaloguj się',
            accountCreated: 'Konto zostało utworzone',
            name: 'Imię',
            surname: 'Nazwisko',
        },
        login: {
            title: 'Zaloguj się na swoje konto',
            enterEmail: 'Wpisz swój email',
            enterPassword: 'Hasło',
            password: 'Hasło',
            noAccount: 'Nie masz konta?',
            createAccount: 'Utwórz nowe konto',
        },
        resetPassword: {
            title: 'Zmień hasło',
            enterEmail: 'Wprowadź swój adres email',
            noAccount: 'Nie masz konta?',
            createAccount: 'Utwórz nowe konto',
            enterPassword: 'Wprowadź hasło',
            confirmPassword: 'Potwierdź hasło',
            password: 'Nowe hasło',
            login: 'Login',
            sendEmail: 'Wyślij email',
            code: 'Kod bezpieczeństwa (wysłany na email)',
            sendCode: 'Wyślij kod bezpieczeństwa',
            save: 'Zapisz',
        },
        contactUs: {
            title: 'Skontaktuj się z nami',
            subtitle:
                'Masz pytania, albo chcesz zaproponować ulepszenia? To super! Napisz do nas, odpiszemy jak najszybciej.',
            email: 'Email address',
            emailTitle: 'Title',
            message: 'Message',
            sendMessage: 'Send message',
            thankYou: 'Thank you for the feedback',
            thankYouSub: "We'll be in touch as soon as possible.",
            backToHome: 'Back to home',
        },
        dashboard: {
            nextEvents: 'Next events',
            noEvents: 'You have no events scheduled for today',
            findNewEvent: 'find a new event now',
            pastEvents: 'Past events',
            timeToVote: 'Time to vote',
            eventsNear: 'Other events near you',
            noEventsNear: 'There are no other events today in',
        },
        userDetails: {
            reports: 'Number of reports for not showing up:',
            joined: 'Joined',
            created: 'Created',
            played: 'Played',
            likes: 'Likes',
            victories: 'Victories',
            defeats: 'Defeats',
            lastEvents: 'Last 30 days event history',
            nextEvents: 'Next events',
        },
        findEvents: {
            city: 'City',
            eventType: 'Event type',
            date: 'Date',
            noEventsTitle: 'Looks like there are no events today...',
            noEventsSub: 'Try changing the filters to search for more events',
        },
        createEvent: {
            partialEventTitle: 'How many people do you need?',
            fullEvent:
                'I want to find all the people for my event on the platform',
            partialEvent: 'I just need a few people to complete my event',
            publicEventTitle: 'How would the users join?',
            publicEvent: 'Everyone can join',
            privateEvent: 'You have to accept new players before they can join',
            name: 'Name',
            namePlaceholder: 'Event name',
            type: 'Type',
            totalNumber: 'Total number of players',
            city: 'City',
            street: 'Street address',
            mapsLink: 'Maps link (optional)',
            mapsLinkPlaceholder: 'For example Google Maps link',
            dateAndTime: 'Date and time',
            duration: 'Duration (in minutes)',
            totalPrice: 'Total price',
            totalPricePlaceholder: 'Leave empty if free',
            currency: 'Currency',
            additionalNotes: 'Additional notes',
            additionalNotesPlaceholder:
                'Add some tips to the players (e.g Team A white shirt, Team B black shirt)',
            createEvent: 'Create event',
        },
        eventDetails: {
            eventCancelled: 'This event has been cancelled',
            cancelDialogTitle: 'Event cancellation',
            cancelDialogBody: 'Are you sure you want to cancel this event?',
            leaveDialogTitle: 'Leaving the event',
            leaveDialogBody: 'Are you sure you want to leave this event?',
            firstTab: 'Details',
            secondTab: 'Board',
            noMessages: 'There are no messages',
            sendMessage: 'Send message',
            acceptRequest: 'Accept request',
            rejectRequest: 'Reject request',
        },
        userSettings: {
            title: 'My settings',
            changeImage: 'Change image',
            saveSettings: 'Save settings',
            saveImage: 'Save image',
            proTip: 'Pro tip: Put a real picture so people can easily recognize you',
        },
    },
};

export const useTranslations = () => {
    const auth = useAppSelector(state => state.auth);
    const language: LANGUAGES = auth.authData?.language!;

    const browserLanguage =
        navigator.language.split('-')[0].toUpperCase() in LANGUAGES
            ? (navigator.language.split('-')[0].toUpperCase() as LANGUAGES)
            : LANGUAGES.EN;

    const t = (query: string, param?: string | number): string => {
        try {
            if (!language && !browserLanguage) {
                throw new Error('invalid language');
            }

            if (query.indexOf('.') === -1) {
                throw new Error('invalid query');
            }

            const queries = query.split('.');

            const translation = language
                ? TRANSLATIONS[language][queries[0]][queries[1]]
                : TRANSLATIONS[browserLanguage!][queries[0]][queries[1]];

            if (!translation) {
                return 'Invalid property';
            }

            if (translation.indexOf('$') !== -1) {
                if (!param) {
                    throw new Error('invalid param');
                }
                return translation.replace('$', param.toString());
            }

            return translation;
        } catch (error) {
            console.log(error);
            return 'Error while translating';
        }
    };

    return t;
};
