import defaultAvatar from '../../../images/defaultavatar.png';
import './userAvatar.css';

interface UserAvatarProps {
    imageSrc?: string | null;
    size?: 's' | 'm' | 'l' | 'xl' | 'xxl';
    onClick?: () => void;
}

const UserAvatar = ({ imageSrc, size = 's', onClick }: UserAvatarProps) => {
    return (
        <img
            className={`profile-img ${size} ${onClick ? 'clickable' : ''}`}
            src={imageSrc || defaultAvatar}
            alt="avatar"
            data-testid="user-avatar"
            onClick={onClick}
        />
    );
};

export default UserAvatar;
