import { format } from 'date-fns';
import { Icon } from '..';
import { COLOURS } from '../../../utils/consts';
import { Auth, User } from '../../../utils/types';
import UserAvatar from '../UserAvatar/UserAvatar';
import Title from '../Title/Title';
import TextField from '../TextField/TextField';
import { useTranslations } from '../../../hooks/translations/useTranslations';

import './userDetails.css';

interface UserDetailsProps {
    user: User | Auth;
    isDashboard?: boolean;
}

const UserDetails: React.FC<UserDetailsProps> = ({
    user,
    isDashboard = false,
}) => {
    const t = useTranslations();

    const title = isDashboard
        ? `${t('common.welcome')}, ${user.name}`
        : `${user.name} ${user.surname}`;

    return (
        <div className="user-details">
            <div className="user-info">
                <UserAvatar imageSrc={user.avatarUrl} size="xxl" />
                <div className="user-info-wrapper">
                    <div className="user-main">
                        <Title text={title} size="xxl" />
                        {user.reports.count > 0 && (
                            <div className="flex gap">
                                <Icon name="bx-error" color={COLOURS.RED} />
                                <TextField color="red">{`${t(
                                    'userDetails.reports'
                                )} ${user.reports.count}`}</TextField>
                            </div>
                        )}
                        <TextField size="s">{`${user.city}, ${user.country}`}</TextField>
                        <TextField size="s">
                            {`${t('userDetails.joined')} 
                                ${format(
                                    new Date(user.createdAt),
                                    'dd/MM/yyyy'
                                )}`}
                        </TextField>
                    </div>
                    <div className="user-stats">
                        <div>
                            <TextField color="green" size="xl" weight="bold">
                                {user.stats.organized}
                            </TextField>
                            <TextField size="s">
                                {t('userDetails.created')}
                            </TextField>
                        </div>
                        <div>
                            <TextField color="green" size="xl" weight="bold">
                                {user.stats.appearances}
                            </TextField>
                            <TextField size="s">
                                {t('userDetails.played')}
                            </TextField>
                        </div>
                        <div>
                            <TextField color="green" size="xl" weight="bold">
                                {user.stats.likes}
                            </TextField>
                            <TextField size="s">
                                {t('userDetails.likes')}
                            </TextField>
                        </div>
                        <div>
                            <TextField color="green" size="xl" weight="bold">
                                {user.stats.mvp}
                            </TextField>
                            <TextField size="s">MVP</TextField>
                        </div>
                        <div>
                            <TextField color="green" size="xl" weight="bold">
                                {user.stats.victories}
                            </TextField>
                            <TextField size="s">
                                {t('userDetails.victories')}
                            </TextField>
                        </div>
                        <div>
                            <TextField color="green" size="xl" weight="bold">
                                {user.stats.defeats}
                            </TextField>
                            <TextField size="s">
                                {t('userDetails.defeats')}
                            </TextField>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
