import { useNavigate } from 'react-router-dom';
import { Auth, IEvent } from '../../../utils/types';
import UserAvatar from '../../common/UserAvatar/UserAvatar';
import { useAppSelector } from '../../../store/hooks';
import { useState } from 'react';
import ReportUserDialog from './ReportUserDialog';
import { Icon, TextField, Title } from '../../common';
import { COLOURS } from '../../../utils/consts';
import { renderUserName } from '../../../utils/helpers';

interface EventDetailsTeamsProps {
    eventDetails: IEvent;
    likes: string[];
    winner: string | null;
    onLikeClick: (userId: string) => void;
    onMarkAsWinner: (teamId: string) => void;
    onConfirmReport: (userId: string) => void;
    isReportLoading: boolean;
}

const VoteEventTeams = ({
    eventDetails,
    likes,
    winner,
    onLikeClick,
    onMarkAsWinner,
    onConfirmReport,
    isReportLoading,
}: EventDetailsTeamsProps) => {
    const navigate = useNavigate();
    const auth = useAppSelector(state => state.auth);

    const isOwner = eventDetails.owner._id === auth.authData?._id;

    const [hoveredTrophy, setHoveredTrophy] = useState<string | null>(null);
    const [hoveredLike, setHoveredLike] = useState<string | null>(null);
    const [showReportsDialog, setShowReportsDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<Auth | null>(null);

    const userClickHandler = (userId: string) => {
        navigate(`/users/${userId}`);
    };

    const onTrophyHover = (teamId: string) => {
        setHoveredTrophy(teamId);
    };

    const onLikeHover = (userId: string) => {
        setHoveredLike(userId);
    };

    const onMouseLeave = () => {
        setHoveredTrophy(null);
        setHoveredLike(null);
    };

    const calculateLikeIconColor = (userId: string) => {
        if (userId === hoveredLike) {
            return COLOURS.GREEN;
        }
        return COLOURS.GREY;
    };

    const calculateReportIconColor = (userId: string) => {
        if (eventDetails.reports.some(report => report === userId)) {
            return COLOURS.RED;
        }
        return COLOURS.GREY;
    };

    const onReportClick = (user: Auth) => {
        setSelectedUser(user);
        setShowReportsDialog(true);
    };

    const confirmReportHandler = () => {
        onConfirmReport(selectedUser?._id as string);
    };

    return (
        <div className="event-details-teams">
            {eventDetails.teams.map((team, index) => (
                <div key={team._id} className="vote-event-team">
                    {!eventDetails.isPartial && (
                        <div className="vote-event-team-name">
                            <Title
                                text={`Team ${index === 0 ? 'A' : 'B'}`}
                                size="l"
                            />
                            {isOwner && (
                                <>
                                    {winner === team._id ? (
                                        <Icon
                                            name="bxs-trophy"
                                            cursor="pointer"
                                            size="50px"
                                            color="gold"
                                            title="Winner"
                                        />
                                    ) : (
                                        <Icon
                                            name="bx-trophy"
                                            color={
                                                hoveredTrophy === team._id
                                                    ? 'gold'
                                                    : '#dedede'
                                            }
                                            onMouseEnter={() =>
                                                onTrophyHover(team._id)
                                            }
                                            onMouseLeave={onMouseLeave}
                                            onClick={() =>
                                                onMarkAsWinner(team._id)
                                            }
                                            cursor="pointer"
                                            size="50px"
                                            title="Winner"
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    <div className="vote-event-team">
                        {team.users.map(user => {
                            const isUserReported = eventDetails.reports.some(
                                report => report === user._id
                            );
                            return (
                                <div
                                    key={user._id}
                                    className="vote-event-user-wrapper"
                                >
                                    <div className="vote-event-user">
                                        <UserAvatar
                                            size="l"
                                            imageSrc={user.avatarUrl}
                                            onClick={() =>
                                                userClickHandler(user._id)
                                            }
                                        />
                                        <TextField>
                                            {renderUserName(
                                                user.name,
                                                user.surname
                                            )}
                                        </TextField>
                                    </div>
                                    <div className="vote-event-stats">
                                        {likes.includes(user._id) ? (
                                            <Icon
                                                name="bxs-like"
                                                cursor="pointer"
                                                color={COLOURS.GREEN}
                                                onClick={() =>
                                                    onLikeClick(user._id)
                                                }
                                            />
                                        ) : (
                                            <div className="vote-event-icons">
                                                {user._id !==
                                                    auth.authData?._id && (
                                                    <>
                                                        {!isUserReported ? (
                                                            <Icon
                                                                name="bx-like"
                                                                color={calculateLikeIconColor(
                                                                    user._id
                                                                )}
                                                                cursor="pointer"
                                                                onClick={() =>
                                                                    onLikeClick(
                                                                        user._id
                                                                    )
                                                                }
                                                                onMouseEnter={() =>
                                                                    onLikeHover(
                                                                        user._id
                                                                    )
                                                                }
                                                                onMouseLeave={
                                                                    onMouseLeave
                                                                }
                                                            />
                                                        ) : (
                                                            <Icon
                                                                cursor="default"
                                                                title="User reported"
                                                                color="#eb4034"
                                                                name="bx-error"
                                                            />
                                                        )}

                                                        {isOwner &&
                                                            !isUserReported && (
                                                                <Icon
                                                                    cursor={
                                                                        isUserReported
                                                                            ? 'default'
                                                                            : 'pointer'
                                                                    }
                                                                    title={
                                                                        isUserReported
                                                                            ? 'User reported'
                                                                            : 'Report user'
                                                                    }
                                                                    color={calculateReportIconColor(
                                                                        user._id
                                                                    )}
                                                                    onClick={() =>
                                                                        isUserReported
                                                                            ? () => {}
                                                                            : onReportClick(
                                                                                  user
                                                                              )
                                                                    }
                                                                    name="bx-error"
                                                                />
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
            <ReportUserDialog
                user={selectedUser}
                isOpen={showReportsDialog}
                isReportLoading={isReportLoading}
                bodyText={`Are you sure you want to report ${selectedUser?.username} for not showing up to the event?`}
                onClose={() => setShowReportsDialog(false)}
                onReportUser={confirmReportHandler}
            />
        </div>
    );
};

export default VoteEventTeams;
